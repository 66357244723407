import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';

export interface _StripeAccount {
    place: number;
    account: string;
    status: string;
};

interface _StripeAccountData extends _StripeAccount {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class StripeAccountData extends DataObject {
    protected _stripe: _StripeAccountData = {
        place: null,
        account: null,
        status: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._stripe.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._stripe.created;
    }

    get status(): string {
        return this._stripe.status;
    }

    set status(value: string) {
        if (this.patchValue(this._stripe, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get account(): string {
        return this._stripe.account;
    }

    set account(value: string) {
        if (this.patchValue(this._stripe, 'account', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._stripe.place,
            account: this._stripe.account,
            status: this._stripe.status
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'stripe', by: 'place' } }   // this[by -> 'place'][to -> 'stripe'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_stripe: _StripeAccount){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._stripe, 'place', _stripe['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._stripe, 'account', _stripe['account']) || _toUpdate;
        _toUpdate = this.patchValue(this._stripe, 'status', _stripe['status']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_stripe: _StripeAccount){
        if (this._patchData(_stripe)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._stripe;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _StripeAccountData {
        let _stripe: _StripeAccountData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            place: info['place'] ? parseInt(info['place']) : null,
            account: info['account'],
            status: info['status']
        };
        return _stripe;
    }

    private DoPatchValues(_stripe: _StripeAccount){
        this._patchData(_stripe);

        if(_stripe['place']){      // update children: 'place'
            let _objid = _stripe['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }  
        else {
            this.SetChild('place', null, 'place');
        }
    }

    protected _OnUpdate(info){
        let _stripe = this._ddbb(info);
        this.patchValue(this._stripe, 'objid', _stripe['objid']);
        this.patchValue(this._stripe, 'created', _stripe['created']);
        this.DoPatchValues(_stripe);
    }
}

export class StripeAccount extends StripeAccountData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('STRIPE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : StripeAccount {
        return this._Copy(store) as StripeAccount;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && this.status != 'DE';
    }

    get IsActive(){
        return this.IsValid && [ 'PV', 'AC' ].includes(this.status);
    }
}

