import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class platformService {
    private _platforms = [];
    private _browser = null;
    
    public _isMobile = false;
    public _isDesktop = false;
    
    private get _OS(){
        let _os = {
            syst: null,     // win / linux / mac
            proc: null,     // 32 / 64
            arch: null,     // ARM / intel
            fext: null,     // executable files extension
        }

    
        let _platform = navigator.platform.toLowerCase();
        let _useragnt = navigator.userAgent.toLowerCase();
        
        let _win = _platform.indexOf('win') > -1;
        let _lin = _platform.indexOf('linux') > -1 || _platform.indexOf('x11') > -1;
        let _mac = _platform.indexOf('mac') > -1;
        let _arm = _useragnt.indexOf('arm') > -1 || _useragnt.indexOf('aarch') > -1;

        if (_win){
            let _is64 = _useragnt.indexOf('win64') > -1 || _useragnt.indexOf('wow64') > -1;
            
            _os.proc = _is64 ? '64' : '32';
            _os.arch = _arm ? 'arm': 'intel';
            _os.syst = 'win';
            _os.fext = '.exe';
        }

        if (_lin){
            let _is64 = _useragnt.indexOf('x86_64') > -1 || _useragnt.indexOf('amd64') > -1;

            _os.proc = _is64 ? '64' : '32';
            _os.arch = _arm ? 'arm': 'intel';
            _os.syst = 'linux'
            _os.fext = '';
        }

        if (_mac){
            _arm = _arm || _useragnt.indexOf('apple') > -1 && navigator.maxTouchPoints > 0;

            _os.proc = '64';
            _os.arch = _arm ? 'arm': 'intel';
            _os.syst = 'mac'
            _os.fext = '';
        }

        if (_os.syst && _os.proc && _os.arch){
            return _os;
        }

        return null;
    }

    private _runningos = null;
    get RunningOS(){  
        if (!this._runningos){
            this._runningos = this._OS;
        }
        return this._runningos;
    }    

    private get _Browser(){ // https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
        // Get the user-agent string
        let userAgentString = navigator.userAgent;
        
        // Detect Chrome
        let chromeAgent = 
            userAgentString.indexOf("Chrome") > -1;
        
        // Detect Internet Explorer
        let IExplorerAgent = 
            userAgentString.indexOf("MSIE") > -1 || 
            userAgentString.indexOf("rv:") > -1;
        
        // Detect Firefox
        let firefoxAgent = 
            userAgentString.indexOf("Firefox") > -1;
        
        // Detect Safari
        let safariAgent = 
            userAgentString.indexOf("Safari") > -1;
                
        // Discard Safari since it also matches Chrome
        if ((chromeAgent) && (safariAgent)) 
            safariAgent = false;
        
        // Detect Opera
        let operaAgent = 
            userAgentString.indexOf("OP") > -1;
                
        // Discard Chrome since it also matches Opera     
        if ((chromeAgent) && (operaAgent)) 
            chromeAgent = false;
        
        if (safariAgent){
            return "Safari";
        }

        if (chromeAgent){
            return "Chrome";
        }

        if (IExplorerAgent){
            return "IE";
        }

        if (operaAgent){
            return "Opera";
        }

        if (firefoxAgent){
            return "Firefox";
        }

        return null;
    }

    get Browser(){  
        if (!this._browser){
            this._browser = this._Browser;
        }
        return this._browser;
    }

    private _onKioskChanged = new Subject<any> ();
    public OnKioskChanged = this._onKioskChanged.asObservable();

    private _kioskmode = false;
    get _isKiosk(){
        return (this.LegacyMobile || this.LegacyTablet) ? false : this._kioskmode;
    }

    set _isKiosk(value){
        if (this.LegacyMobile || this.LegacyTablet){
            return;     // cannot set kiosk when native keyboard is available
        }

        if (value != this._kioskmode){
            this._kioskmode = value;
            this._onKioskChanged.next();

            if (this._kioskmode){
                console.info("[KIOSK] using virtual keyboard");
            }
            else {
                console.info("[KIOSK] using phisical keyboard");
            }    
        }
    }

    set _viewMode(value){
        let _mode = value.toLowerCase();
        
        let _reload = false;
        if ((_mode == 'mobile') && !this._ismobile){
            _reload = true;
        }

        if ((_mode == 'desktop') && !this._isdesktop){
            _reload = true;
        }

        if (_reload){
            this.ForceMode(_mode);
            console.info("[FORCE MODE] forcing to '" + _mode + "' and reloading");

            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    mode: _mode
                },
    
                queryParamsHandling: 'merge',   // preserve the existing query params in the route
                skipLocationChange: false       // update the url params
              }).then(data => {
                    // nothing to do
              });                
        }
    }

    private _onThemeChanged = new Subject<any> ();
    public OnThemeChanged = this._onThemeChanged.asObservable();

    private _bodytheme = 'dark';
    get _theme(){
        return this._bodytheme;
    }

    set _theme(value){
        if (((value == 'dark') || (value == 'light')) && (value != this._bodytheme)){
            if (document.body.classList.contains(this._bodytheme)){
                document.body.classList.remove(this._bodytheme);
            }

            this._bodytheme = (this._theme == 'dark') ? 'light' : 'dark';

            document.body.classList.add(this._bodytheme);
            this._onThemeChanged.next();
        }
    }

    private _bodyzoom = 1;
    get _zoomLevel(){
        return this._bodyzoom;
    }

    set _zoomLevel(value){
        if (this._bodyzoom != value){
            this._bodyzoom = value;

            let _root = document.documentElement;
            if (_root){
                _root.style.setProperty('--body-scale-width', ((1 / this._bodyzoom) * 100).toString() + '%');
                _root.style.setProperty('--body-scale-height', ((1 / this._bodyzoom) * 100).toString() + '%');
                _root.style.setProperty('--body-scale-level', this._bodyzoom.toString());

                _root.style.setProperty('--body-zoom-level', (this._bodyzoom * 100).toString() + '%');
                _root.style.setProperty('--body-zoom-width', '100%');
                _root.style.setProperty('--body-zoom-height', ((1 - this._bodyzoom) * 100) + '%');
            }    
        }
    }

    private _showscroll = false;
    get _scrollbar(){
        return this._showscroll;
    }

    set _scrollbar(value){
        this._showscroll = value;
        this._onThemeChanged.next();
    }

    private _angular_add(_platforms){
        for(let _platform of _platforms) {
            if (this.platform.is(_platform)){
                this._platforms.push(_platform);
            }
        }
    }

    private _detector_add(_platforms){
        for(let _platform of _platforms) {
            let _available = false;

            switch(_platform){
                case 'mobile':
                    _available = this.detector.isMobile();
                    break;
                case 'tablet':
                    _available = this.detector.isTablet();
                    break;
                case 'desktop':
                    _available = this.detector.isDesktop();
                    break;
            }

            if (_available){
                this._platforms.push(_platform);
            }
        }
    }
    
    get DeviceInfo(){
        return this.detector.getDeviceInfo();
    }
    
    private _forcedmode = null;
    private ForceMode(mode){
        let _mode = mode.toLowerCase();

        if (_mode.toLowerCase() == 'desktop'){
            this._forcedmode = 'desktop';
        }
        
        if (_mode.toLowerCase() == 'mobile'){
            this._forcedmode = 'mobile';
        }
    }

    private _initialize(){
        this._platforms = []

        console.info("[DEVICE] running on browser: [" + this.Browser + "]")
        console.info("[DEVICE] identified platforms ", this.platform.platforms());
        
        let _urlMode = this.route.snapshot.queryParamMap.get("mode");
        if (_urlMode){  // use url mode
            this.ForceMode(_urlMode);        
        }

        this._angular_add(['cordova', 'ios', 'android']);
        this._detector_add(['mobile', 'tablet', 'desktop']);
        
        if (this._forcedmode){
            console.info("[DEVICE] platform forced to ", [ this._forcedmode ]);        
        }
        else {
            console.info("[DEVICE] running on platforms ", this._platforms);        
        }

        // retrieve the device mode in the url parameters
        let _subscription = this.route.queryParams.subscribe(
        params => {
            if (JSON.stringify(params) !== '{}'){
                _subscription.unsubscribe();    // parameters is not empty
            }   

            if ('mode' in params){
                this.ForceMode(params['mode']);      

                if (this._forcedmode){
                    console.info("[DEVICE] platform forced to ", [ this._forcedmode ]);        
                }
                else {
                    console.info("[DEVICE] running on platforms ", this._platforms);        
                }
        
                this._isMobile = this._ismobile;
                this._isDesktop = this._isdesktop;    
            }
        });
    }

    constructor(private detector: DeviceDetectorService, private platform: Platform, private router: Router, private route: ActivatedRoute) {
        this.platform.ready().then(() => {
            this._initialize();
            
            this._isMobile = this._ismobile;
            this._isDesktop = this._isdesktop;
        });
    }
    
    /****************************************/
    /* Wrap the platform used methods       */
    /****************************************/

    get resize(){
        return this.platform.resize;
    }
    
    ready(){
        return this.platform.ready();
    }
        
    platforms(){
        return this._platforms;
    }
    
    is(_platform) {
        let _retval = (this._platforms.indexOf(_platform) != -1);

        if (!_retval){  
            // specific android check
            if (_platform == 'android'){
                if (window.navigator.userAgent.toLowerCase().indexOf('android') > -1) {
                    return true;
                }
            }

            // specific ios check
            if (_platform == 'ios'){
                if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
                    return true;
                }
            }
        }

        return _retval; 
    }    
    
    /****************************************/
    /* Query large devices / mobile devices */
    /****************************************/
    
    private get _movileOS(){
        return (this.platform.is('ios') || this.platform.is('android'))
    }

    get LegacyMobile(){
        if (this.platform.is('tablet')){
            return this.platform.is('iphone');  // iphones may identify themselves as tablet devices
        }
        else {
            return this.platform.is('mobile') && this._movileOS;
        }
    }

    get LegacyTablet(){
        return !this.LegacyMobile && !this.platform.is('desktop') && this.platform.is('tablet') && this._movileOS;
    }

    get LegacyDesktop(){
        return !this.LegacyMobile;
    }

    private get _ismobile(){    // true if is mobile (not tablet)
        if (this._forcedmode){
            return (this._forcedmode == 'mobile');
        }

        return this.LegacyMobile;
    }

    private get _isdesktop(){
        if (this._forcedmode){
            return (this._forcedmode == 'desktop');
        }

        return !this._ismobile
    }
}
