import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Offer } from './offer';
import { OfferProductOption } from './offeroption' 
import { Product } from './product';

export interface _OfferProduct {
    status: string;
    offer: number;
    product: number;
    grouped: number;
};

interface _OfferProductData extends _OfferProduct {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class OfferProductData extends DataObject {
    protected _offerproduct: _OfferProductData = {
        status: null,
        offer: null,
        product: null,
        grouped: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._offerproduct.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._offerproduct.created;
    }

    get status(): string {
        return this._offerproduct.status;
    }

    set status(value: string) {
        if(this.patchValue(this._offerproduct, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get offer(): Offer {
        return this._children['offer'] || null;
    }

    set offer(value: Offer ){
        if (this.SetChild('offer', value, 'offer')){
            this.ToUpdate = true;
        }
    }

    get product() : Product {
        return this._children['product'] || null;
    }

    set product(value: Product) {
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get grouped(): number {
        return this._offerproduct.grouped;
    }

    set grouped(value: number) {
        if (this.patchValue(this._offerproduct, 'grouped', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: OfferProductOption){
        this.AddChild('options', child, 'product');
    }

    DelOption(child: OfferProductOption){
        this.DelChild('options', child, 'product');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <OfferProductOption> {
        return this._chldlist['options'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._offerproduct.status, 
            offer: this._offerproduct.offer,
            product: this._offerproduct.product,
            grouped: this._offerproduct.grouped
        };
    }

    protected get Depend() {
        return {
            offer: { item: this.offer, relation_info: { to: 'products', by: 'offer' } },    // this[by -> 'offer'][to -> 'products'] => this
            product: { item: this.product, relation_info: { to: null, by: 'product' } }     // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.options){
            _children.push(_item);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_offerproduct: _OfferProduct){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._offerproduct, 'status', _offerproduct['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._offerproduct, 'offer', _offerproduct['offer']) || _toUpdate;
        _toUpdate = this.patchValue(this._offerproduct, 'product', _offerproduct['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._offerproduct, 'grouped', _offerproduct['grouped']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_offerproduct: _OfferProduct){
        if (this._patchData(_offerproduct)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._offerproduct;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_offerproduct: _OfferProduct){
        this._patchData(_offerproduct);

        if (_offerproduct['product']){     // update children: 'product'
            let _objid = _offerproduct['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product');
        }
        else {
            this.SetChild('product', null, 'product');
        }

        if (_offerproduct['offer']){     // update children: 'offer'
            let _objid = _offerproduct['offer'].toString();
            this.SetChild('offer', new Offer(_objid, this.data, this._objoptions), 'offer');
        }
        else {
            this.SetChild('offer', null, 'offer');
        }
    }

    private _ddbb(info): _OfferProductData {
        let _offerproduct: _OfferProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            offer: info['offer'] ? parseInt(info['offer']) : null,
            product: info['product'] ? parseInt(info['product']): null,
            grouped: info['grouped'] ? parseInt(info['grouped']) : 0
        };
        return _offerproduct;
    }

    protected _OnUpdate(info){
        let _offerproduct = this._ddbb(info);
        this.patchValue(this._offerproduct, 'objid', _offerproduct['objid']);
        this.patchValue(this._offerproduct, 'created', _offerproduct['created']);
        this.DoPatchValues(_offerproduct);

        if (info['options']){     // update children: 'options'
            this.SetChildren <OfferProductOption> (info['options'], 'options', OfferProductOption, 'product');
        }
    }   
}

export class OfferProduct extends OfferProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('OFFERPRODUCT', objid, data, objoptions);
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []): OfferProduct {
        return this._Copy(store) as OfferProduct;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if ((this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.offer.DelProduct(this);
            }
            this.offer.ToUpdate = true;
        }                

        super.status = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE') && this.product.IsValid;
    }    
}
