import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Extra } from './extra';
import { Offer } from './offer';
import { Family } from './family';

export interface _Period {
    extra?: number,
    offer?: number,
    family?: number,
    status: string,
    weekdays: string;
    ini: string;
    end: string;
    timezone: number;
};

interface _PeriodData extends _Period {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

export abstract class PeriodData extends DataObject {
    protected _period: _PeriodData = {
        extra: null,
        offer: null,
        family: null,
        status: 'AC',
        weekdays: '0000000',
        ini: null,
        end: null,
        timezone: new Date().getTimezoneOffset()
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._period.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._period.created;
    }

    get status(): string {
        return this._period.status;
    }

    set status(value: string) {
        if(this.patchValue(this._period, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get weekdays(): string {
        return this._period.weekdays;
    }

    set weekdays(value: string){
        if (this.patchValue(this._period, 'weekdays', value)){
            this.ToUpdate = true;
        }
    }

    get ini(): string {
        return this._period.ini;
    }

    set ini(value: string){
        if (this.patchValue(this._period, 'ini', value)){
            this.ToUpdate = true;
        }
    }

    get end(): string {
        return this._period.end;
    }

    set end(value: string){
        if (this.patchValue(this._period, 'end', value)){
            this.ToUpdate = true;
        }
    }

    get timezone(): number {
        return this._period.timezone;
    }

    set timezone(value: number) {
        if (this.patchValue(this._period, 'timezone', value)){
            this.ToUpdate = true;
        }
    }
   
    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_period: _Period){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._period, 'extra', _period['extra']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'offer', _period['offer']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'family', _period['family']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'status', _period['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'weekdays', _period['weekdays']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'ini', _period['ini']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'end', _period['end']) || _toUpdate;
        _toUpdate = this.patchValue(this._period, 'timezone', _period['timezone']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_period: _Period){
        if (this._patchData(_period)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._period;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _PeriodData {
        let _period: _PeriodData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            extra: ('extra' in info) ? parseInt(info['extra']) : null,
            offer: ('offer' in info) ? parseInt(info['offer']) : null,
            family: ('family' in info) ? parseInt(info['family']) : null,
            status: info['status'],
            weekdays: info['weekdays'],
            ini: info['ini'],
            end: info['end'],
            timezone: info['timezone'],
        };
        return _period;
    }

    private DoPatchValues(_period: _Period){
        this._patchData(_period);

        if(_period['extra']){      // update children: 'extra'
            let _objid = _period['extra'].toString();
            this.SetChild('extra', new Extra(_objid, this.data, this._objoptions), 'extra')
        }      
        else {
            this.SetChild('extra', null, 'extra');
        }

        if(_period['offer']){      // update children: 'offer'
            let _objid = _period['offer'].toString();
            this.SetChild('offer', new Offer(_objid, this.data, this._objoptions), 'offer')
        }
        else {
            this.SetChild('offer', null, 'offer');
        }

        if(_period['family']){      // update children: 'family'
            let _objid = _period['family'].toString();
            this.SetChild('family', new Offer(_objid, this.data, this._objoptions), 'family')
        }
        else {
            this.SetChild('family', null, 'family');
        }
    }

    protected _OnUpdate(info){
        let _period = this._ddbb(info);
        this.patchValue(this._period, 'objid', _period['objid']);
        this.patchValue(this._period, 'created', _period['created']);
        this.DoPatchValues(_period);
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    private get _IsValid(){
        if (!this.ini || !this.end || (this.weekdays == '0000000')){
            return false;   // invalid data
        }

        let _part_ini = this.ini.split(':'); 
        let _part_end = this.end.split(':'); 

        let _date_ini = new Date(2000, 0, 1, parseInt(_part_ini[0]), parseInt(_part_ini[1]));
        let _date_end = new Date(2000, 0, 1, parseInt(_part_end[0]), parseInt(_part_end[1]));

        if (isNaN(_date_ini.getTime()) || isNaN(_date_end.getTime())){
            return false;   // invalid date
        }

        if (_date_ini >= _date_end){
            return false;   // date ini is greater
        }

        return true;
    }

    get IsValid(){
        return this.status && (this.status != 'DE') && this._IsValid;
    }

    private toDate(hhmmss){
        let _parts = hhmmss.split(':');
        let _hh = parseInt(_parts[0]);
        let _mm = parseInt(_parts[1]);

        let _date = new Date();
        _date.setHours(_hh, _mm);
        return _date;
    }

    get IsActive(){
        if (!this.IsValid){
            return false;
        }
        
        let _ini = this.toDate(this._period.ini);
        let _end = this.toDate(this._period.end);
        _end.setMinutes(_end.getMinutes() + 1);     // add 1 minute to the end date (23:59 -> 00:00)

        let _now = new Date();        
        let _day = (_now.getDay() == 0) ? 6 : (_now.getDay() - 1);

        return (this._period.weekdays[_day] == '1') && (_now.getTime() >= _ini.getTime()) && (_now.getTime() <= _end.getTime());
    }

    get OfferChange(){
        return {
            offer: this._period.offer,
            status: this._period.status,
            weekdays: this._period.weekdays,
            ini: this._period.ini,
            end: this._period.end,
            timezone: this._period.timezone
        };
    }

    get ExtraChange(){
        return {
            extra: this._period.extra,
            status: this._period.status,
            weekdays: this._period.weekdays,
            ini: this._period.ini,
            end: this._period.end,
            timezone: this._period.timezone
        };
    }

    get FamilyChange(){
        return {
            family: this._period.family,
            status: this._period.status,
            weekdays: this._period.weekdays,
            ini: this._period.ini,
            end: this._period.end,
            timezone: this._period.timezone
        };
    }
}

export class OfferPeriod extends PeriodData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('OFFERPERIOD', objid, data, objoptions);
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return this.OfferChange;
    }

    protected get Depend() {
        return {
            offer: { item: this.offer, relation_info: { to: 'periods', by: 'offer' } }   // this[by -> 'offer'][to -> 'periods'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []) : OfferPeriod {
        return this._Copy(store) as OfferPeriod;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if (this.ToInsert){
                this.offer.DelPeriod(this);
            }
            this.offer.ToUpdate = true;
        }                

        super.status = value;
    }

    get offer() : Offer {
        return this._children['offer'] || null;
    }

    set offer(value: Offer) {
        if (this.SetChild('offer', value, 'offer')){
            this.ToUpdate = true;
        }
    }
}

export class ExtraPeriod extends PeriodData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('EXTRAPERIOD', objid, data, objoptions);
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return this.ExtraChange;
    }

    protected get Depend() {
        return {
            extra: { item: this.extra, relation: { to: 'periods', by: 'extra' } }   // this[by -> 'extra'][to -> 'periods'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []) : ExtraPeriod {
        return this._Copy(store) as ExtraPeriod;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if (this.ToInsert){
                this.extra.DelPeriod(this);
            }
            this.extra.ToUpdate = true;
        }                

        super.status = value;
    }

    get extra() : Extra {
        return this._children['extra'] || null;
    }

    set extra(value: Extra){
        if (this.SetChild('extra', value, 'extra')){
            this.ToUpdate = true;
        }
    }
}

export class FamilyPeriod extends PeriodData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('FAMILYPERIOD', objid, data, objoptions);
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return this.FamilyChange;
    }

    protected get Depend() {
        return {
            family: { item: this.family, relation: { to: 'periods', by: 'family' } }   // this[by -> 'family'][to -> 'periods'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []) : ExtraPeriod {
        return this._Copy(store) as ExtraPeriod;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if (this.ToInsert){
                this.family.DelPeriod(this);
            }
            this.family.ToUpdate = true;
        }                

        super.status = value;
    }

    get family() : Family {
        return this._children['family'] || null;
    }

    set family(value: Family){
        if (this.SetChild('family', value, 'family')){
            this.ToUpdate = true;
        }
    }
}
