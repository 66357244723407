import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { ServiceConnect } from './svcconnect';
import { PrintDevice } from './printer';
import { ScaleDevice } from './scale';
import { QrCode } from './qrcode';

export interface _PlaceService {
    place: number;
    id: string;
    ping: Date;
    status: string;
    version: string;
};

interface _PlaceServiceData extends _PlaceService {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class PlaceServiceData extends DataObject {
    protected _service: _PlaceServiceData = {
        place: null,
        id: null,
        ping: null,
        status: null,
        version: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._service.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date {
        return this._service.created;
    }

    get updated(): Date {
        return this._service.updated || this._service.created;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._service.id;
    }

    set name(value: string) {
        if (this.patchValue(this._service, 'id', value)){
            this.ToUpdate = true;
        }
    }

    get ping(): Date {
        return this._service.ping;
    }

    get status(): string{
        return this._service.status;
    }

    set status(value: string){
        if (this.patchValue(this._service, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get version(): string{
        let _version = this._service.version;
        if (_version == "0.0.0.0"){
            return null;
        }
        return _version;
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPrinter(child: PrintDevice){
        this.AddChild('printers', child, 'rasppi');
    }

    DelPrinter(child: PrintDevice){
        this.DelChild('printers', child, 'rasppi');
    }

    AddScale(child: ScaleDevice){
        this.AddChild('scales', child, 'rasppi');
    }

    DelScale(child: ScaleDevice){
        this.DelChild('scales', child, 'rasppi');
    }

    AddConnect(child: ServiceConnect){
        this.AddChild('connect', child, 'rasppi');
    }

    DelConnect(child: ServiceConnect){
        this.DelChild('connect', child, 'rasppi');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get connects() : Array <ServiceConnect> { 
        return this._chldlist['connect'] || [];
    }

    get printers() : Array <PrintDevice> {
        return this._chldlist['printers'] || [];
    }

    get scales(): Array <ScaleDevice> {
        return this._chldlist['scales'] || [];
    }
    
    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._service.place,
            id: this._service.id,
            status: this._service.status
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'printer', by: 'place' } }       // this[by -> 'place'][to -> 'printer'] => this
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.connects){
            _children.push(_item)
        }

        for(let _item of this.printers){
            _children.push(_item)
        }

        for(let _item of this.scales){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_service: _PlaceService){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._service, 'place', _service['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._service, 'id', _service['id']) || _toUpdate;
        _toUpdate = this.patchValue(this._service, 'version', _service['version']) || _toUpdate;
        _toUpdate = this.patchValue(this._service, 'ping', _service['ping']) || _toUpdate;
        _toUpdate = this.patchValue(this._service, 'status', _service['status']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_service: _PlaceService){
        this.patchValue(this._service, 'created', _service['created']);
        this.patchValue(this._service, 'updated', _service['updated']);
        
        if (this._patchData(_service)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._service;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_service: _PlaceService){
        this._patchData(_service);

        if (_service['place']){     // update children: 'place'
            let _objid = _service['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _PlaceServiceData {
        let _service: _PlaceServiceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            place: info['place'] ? parseInt(info['place']) : null,
            id: info['id'],
            ping: info['ping'] ? new Date(Date.parse(this.mysqlToDateStr(info['ping']))) : null,
            status: info['status'],
            version: info['version']
        };
        return _service;
    }

    protected _OnUpdate(info){
        let _service = this._ddbb(info);
        this.patchValue(this._service, 'objid', _service['objid']);
        this.patchValue(this._service, 'created', _service['created']);
        this.patchValue(this._service, 'updated', _service['updated']);
        this.DoPatchValues(_service);

        if (info['connect']){   // update children: 'connect'
            this.SetChildren <ServiceConnect> (info['connect'], 'connect', ServiceConnect, 'rasppi');
        }

        if (info['printers']){   // update children: 'printers'
            this.SetChildren <PrintDevice> (info['printers'], 'printers', PrintDevice, 'rasppi');
        }

        if (info['scales']){   // update children: 'scales'
            this.SetChildren <ScaleDevice> (info['scales'], 'scales', ScaleDevice, 'rasppi');
        }
    }
}

export class PlaceService extends PlaceServiceData {

    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('RASPPI', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): PlaceService {
        return this._Copy(store) as PlaceService;
    }

    /****************************/
    /* BASE OVERLOAD           */
    /****************************/

    UpRefresh(){
        // nothing to do: do not update place
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get connect() : ServiceConnect {
        let _device = this.data.device;
        for(let _connect of this.connects){
            if (_connect.IsValid && (_connect.device == _device)){
                return _connect;    
            }
        }

        return null;    // no stored connection for the current device
    }

    get ws(): string {
        if (this.connect){
            return this.connect.connect;
        }
        return null;
    }

    get wshost(){
        return this.ws ? this.ws.split(':')[0] : null;
    }

    get wsport(){
        return this.ws ? this.ws.split(':')[1] : null;
    }

    /* NOTE: previous version returns 'ONLINE'/'OFFLINE' */
    get status(): string {
        let _status = super.status;
        switch(_status){
            case 'ONLINE':
                return 'AC';
            case 'OFFLINE':
                return 'DE'
        }

        return _status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (super.status != value){
            super.status = value;
            
            if (this.place){
                if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                    this.place.DelService(this);
                }
                else {
                    this.place.DoRefresh('RASPPI');
                }        
            }
        }
    }

    // obtain one connect per device (the last updated one)
    get connects(){
        let _connects = new Map <string, ServiceConnect> ();
        for (let _connect of super.connects){
            if (!_connect.IsValid || (_connect.rasppi != this)){
                continue;
            }

            let _candidate = _connects.get(_connect.device);
            if (!_candidate || (_connect.updated > _candidate.updated)){
                _connects.set(_connect.device, _connect);
            }
        }

        return [..._connects.values()];
    }

    private _isonline = null;
    get IsOnline() : boolean {
        return this._isonline;
    }

    set IsOnline(value: boolean){
        if (value != this._isonline){
            this._isonline = value;
            this.DoRefresh('RASPPI');
        }
    }

    get tables(){
        let _tables = [];

        for (let _table of this.place.tables){
            if (_table.IsValid && (_table.till == this)){
                _tables.push(_table);
            }
        }

        return _tables;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status == 'AC');
    }

    get IsLocal(){
        return (this.connect != null);
    }

    DoConnect(){
        let _connect = this.connect;
        if (!_connect){
            _connect = new ServiceConnect(null, this.data);
            if (_connect){
                _connect.rasppi = this;
                _connect.device = this.data.device;
                _connect.connect = null;
                _connect.status = 'AC';
            }

            this.AddConnect(_connect);
        }

        return _connect;
    }
}
