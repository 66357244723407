import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PlaceService } from './placeservice';

export interface _DeviceConnect {
    rasppi: number;
    device: string;
    connect: string;
};

interface _DeviceConnectData extends _DeviceConnect {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class DeviceConnectData extends DataObject {
    protected _connect: _DeviceConnectData = {
        rasppi: null,
        device: null,
        connect: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._connect.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date {
        return this._connect.created;
    }

    get updated(): Date {
        return this._connect.updated || this._connect.created;
    }

    get rasppi() : PlaceService {
        return this._children['rasppi'] || null;
    }

    set rasppi(value: PlaceService){
        if (this.SetChild('rasppi', value, 'rasppi')){
            this.ToUpdate = true;
        }
    }

    get device(): string {
        return this._connect.device;
    }

    set device(value: string){
        if (this.patchValue(this._connect, 'device', value)){
            this.ToUpdate = true;
        }
    }

    get connect(): string {
        return this._connect.connect;
    }

    set connect(value: string) {
        if (this.patchValue(this._connect, 'connect', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            rasppi: this._connect.rasppi,
            device: this._connect.device,
            connect: this._connect.connect,
        };
    }

    protected get Depend() {
        return {
            rasppi: { item: this.rasppi, relation_info: { to: 'connect', by: 'rasppi' } }   // this[by -> 'rasppi'][to -> 'connect'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_connect: _DeviceConnect){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._connect, 'rasppi', _connect['rasppi']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'device', _connect['device']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'connect', _connect['connect']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_connect: _DeviceConnect){
        if (this._patchData(_connect)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._connect;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_connect: _DeviceConnect){
        this._patchData(_connect);

        if (_connect['rasppi']){     // update children: 'rasppi'
            let _objid = _connect['rasppi'].toString();
            this.SetChild('rasppi', new PlaceService(_objid, this.data, this._objoptions), 'rasppi')
        }
        else {
            this.SetChild('rasppi', null, 'rasppi');
        }
    }

    private _ddbb(info): _DeviceConnectData {
        let _connect: _DeviceConnectData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            rasppi: info['rasppi'] ? parseInt(info['rasppi']) : null,
            device: info['device'],
            connect: info['connect']
        };
        return _connect;
    }

    protected _OnUpdate(info){
        let _connect = this._ddbb(info);
        this.patchValue(this._connect, 'objid', _connect['objid']);
        this.patchValue(this._connect, 'created', _connect['created']);
        this.patchValue(this._connect, 'updated', _connect['updated']);
        this.DoPatchValues(_connect);
    }
}

export class DeviceConnect extends DeviceConnectData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('RASPPICONNECT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): DeviceConnect {
        return this._Copy(store) as DeviceConnect;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get connecthost(){
        return this.connect.split(':')[0];
    }

    get connectport(){
        return this.connect.split(':')[1];
    }
}
