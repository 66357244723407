import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { CatalogObject } from './catalog';
import { Product } from './product';
import { ProductCategory } from './productcategory';

export interface _ProductOption {
    status: string;
    active: boolean;
    ctgref?: string;
    product: number;
    catalog: boolean;
    category: number;
    name: string;
    description: string;
    price: number;
    sort: number;
};

interface _ProductOptionData extends _ProductOption {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ProductOptionData extends CatalogObject {
    protected _option: _ProductOptionData = {
        status: null,
        active: null,
        ctgref: null,
        product: null,
        catalog: null,
        category: null,
        name: null,
        description: null,
        price: null,
        sort: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._option.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._option.created;
    }

    get status(): string{
        return this._option.status;
    }

    set status(value: string){
        if (this.patchValue(this._option, 'status', value)){
            this.ToUpdate = true;
        }              
    }

    get active() : boolean {
        return this._option.active;
    }

    set active(value: boolean){
        this._option.active = value;
    }

    get ctgref(): string{
        return this._option.ctgref;
    }

    set ctgref(value: string){
        if (this.patchValue(this._option, 'ctgref', value)){
            this.ToUpdate = true;
        }              
    }

    get catalog(): boolean {
        return this._option.catalog;
    }

    get product(): Product {
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate= true;
        }
    }

    get category(): ProductCategory {
        return this._children['category'] || null;
    }

    set category(value: ProductCategory){
        if (this.SetChild('category', value, 'category')){
            this.ToUpdate = true;
        }
    }

    get name(): string{
        return this._option.name;   
    }

    set name(value: string){
        if (this.patchValue(this._option, 'name', value)){
            this.ToUpdate = true;
        }              
    }

    get description(): string{
        return this._option.description;
    }

    set description(value: string){
        if (this.patchValue(this._option, 'description', value)){
            this.ToUpdate = true;
        }              
    }

    get price(): number{
        return this._option.price;
    }

    set price(value: number){
        if (this.patchValue(this._option, 'price', value)){
            this.ToUpdate = true;
        }                
    }

    get sort(): number{
        return this._option.sort;
    }

    set sort(value: number){
        if (this.patchValue(this._option, 'sort', value)){
            this.ToUpdate = true;
        }        
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        if (!this._option.active){
            this.status = 'DE';
        }

        return {
            status: this._option.status,
            product: this._option.product,
            catalog: (this._option.catalog) ? '1': '0',
            category: this._option.category,
            name: this._option.name,
            description: this._option.description,
            price: this._option.price,
            sort: this._option.sort
        };
    }

    protected get Depend() {
        return {
            category: { item: this.category, relation_info: { to: 'options', by: 'category' } },    // this[by -> 'category'][to -> 'options'] => this
            product: { item: this.product, relation_info: { to: null, by: 'product' } }             // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_option: _ProductOption){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._option, 'status', _option['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'active', _option['active']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'product', _option['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'catalog', _option['catalog']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'ctgref', _option['ctgref']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'category', _option['category']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'name', _option['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'description', _option['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'price', _option['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'sort', _option['sort']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_option: _ProductOption){
        if (this._patchData(_option)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._option;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_option: _ProductOption){
        this._patchData(_option);
        
        if (_option['product']){       // update children 'product'
            let _objid = _option['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product');
        }
        else {
            this.SetChild('product', null, 'product');
        }

        if (_option['category']){      // update children 'category'
            let _objid = _option['category'].toString();
            this.SetChild('category', new ProductCategory(_objid, this.data, this._objoptions), 'category');
        }
        else {
            this.SetChild('category', null, 'category');
        }
    }

    private _ddbb(info): _ProductOptionData {
        let _option: _ProductOptionData = {
            objid: info['objid'],
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            active: (info['status'] != 'DE'),
            product: info['product'] ? parseInt(info['product']) : null,
            catalog: (info['catalog'] == '1'),
            category: info['category'] ? parseInt(info['category']) : null,
            name: info['name'],
            description: info['description'],
            price: info['price'] ? parseFloat(info['price']) : null,
            sort: info['sort'] ? parseInt(info['sort']) : 0
        };
        return _option;
    }

    protected _OnUpdate(info){
        let _option = this._ddbb(info);
        this.patchValue(this._option, 'objid', _option['objid']);
        this.patchValue(this._option, 'created', _option['created']);
        this.DoPatchValues(_option);
    }
}

export class ProductOption extends ProductOptionData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRODUCTOPT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ProductOption {
        return this._Copy(store) as ProductOption;
    }

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value != this.status){
            super.status = value;

            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.category.DelOption(this);
            }

            this.product.DoRefresh('PRODUCTOPT', true);
        }
    }
    
    get available() : boolean {
        return (this.status == 'AC');
    }

    set available(value: boolean){
        this.status = (value) ? 'AC' : 'UN';
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status == 'AC') || (this.status == 'UN');
    }

    get IsAvailable(){
        return this.available;
    }

    get IsMandatory(){
        return (this.category.type == 'SINGLE');
    }

    get Price(){
        let _price = Number(this.price || 0);

        if (this.price !== null){
            let _parts = (Math.abs(_price)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _price.toFixed(2);
            let _neg = (_price < 0); 

            return {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        return null;
    }    
}
