import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { Ticket } from './ticket';

export interface _Payment {
    status: string;
    place: number;     // for license extend
    ticket: number;    // for ticket payments
    payer: string;
    reason: string;
    amount: number;
    currency: string;
    txnid: string;
};

interface _PaymentData extends _Payment {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class PaymentData extends DataObject {
    protected _payment: _PaymentData = {
        status: null,
        place: null,
        ticket: null,
        payer: null,
        reason: null,
        amount: null,
        currency: null,
        txnid: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._payment.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._payment.created;
    }

    get updated(): Date {
        return this._payment.updated || this._payment.created;
    }

    get status(): string{
        return this._payment.status;
    }

    set status(value: string){
        if (this.patchValue(this._payment, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place(): Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get ticket() : Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get payer(): string {
        return this._payment.payer;
    }

    set payer(value: string) {
        if (this.patchValue(this._payment, 'payer', value)){
            this.ToUpdate = true;
        }
    }

    get reason(): string {
        return this._payment.reason;
    }

    set reason(value: string) {
        if (this.patchValue(this._payment, 'reason', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._payment.amount;
    }

    set amount(value: number) {
        if (this.patchValue(this._payment, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    get currency(): string {
        return this._payment.currency;
    }

    set currency(value: string) {
        if (this.patchValue(this._payment, 'currency', value)){
            this.ToUpdate = true;
        }
    }

    get txnid(): string {
        return this._payment.txnid;
    }

    set txnid(value: string) {
        if (this.patchValue(this._payment, 'txnid', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            source: this._payment.place,
            ticket: this._payment.ticket,
            status: this._payment.status,
            payer: this._payment.payer,
            reason: this._payment.reason,
            amount: this._payment.amount,
            currency: this._payment.currency,
            txnid: this._payment.txnid
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'transaction', by: 'place' }  },    // this[by -> 'place'][to -> 'transaction'] => this
            ticket: { item: this.ticket, relation_info: { to: 'transaction', by: 'ticket' } }   // this[by -> 'ticket'][to -> 'transaction'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_payment: _Payment){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._payment, 'status', _payment['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'source', _payment['source']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'ticket', _payment['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'payer', _payment['payer']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'reason', _payment['reason']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'amount', _payment['amount']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'currency', _payment['currency']) || _toUpdate;
        _toUpdate = this.patchValue(this._payment, 'txnid', _payment['txnid']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_payment: _Payment){
        if (this._patchData(_payment)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._payment;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _PaymentData {
        let _payment: _PaymentData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'], 
            place: info['source'] ? parseInt(info['source']) : null,
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            payer: info['payer'],
            reason: info['reason'],
            amount: info['amount'] ? parseFloat(info['amount']) : 0,
            currency: info['currency'],
            txnid: info['txnid']
        };
        return _payment;
    }

    private DoPatchValues(_payment: _Payment){
        this._patchData(_payment);

        if(_payment['source']){     // update children: 'place'
            let _objid = _payment['source'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place');
        }        
        else {
            this.SetChild('place', null, 'place');
        }

        if(_payment['ticket']){     // update children: 'ticket
            let _objid = _payment['ticket'].toString();
            this.SetChild('ticket', new Place(_objid, this.data, this._objoptions), 'ticket');
        }    
        else {
            this.SetChild('ticket', null, 'ticket');
        }
    }

    protected _OnUpdate(info){
        let _payment = this._ddbb(info);
        this.patchValue(this._payment, 'objid', _payment['objid']);
        this.patchValue(this._payment, 'created', _payment['created']);
        this.patchValue(this._payment, 'updated', _payment['updated']);
        this.DoPatchValues(_payment);
    }
}

export class Payment extends PaymentData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PAYMENT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Payment {
        return this._Copy(store) as Payment;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (value != this.status){
            super.status = value;
            this.place.DoRefresh('PAYMENT');    
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status != null);
    }

    get Amount(){
        let _payment = {
            Int: "0",
            Dec: "00",
            Str: "0,00",
            Neg: false
        };

        if (this.amount !== null){
            let _parts = (Math.abs(this.amount)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _int + ',' + _dec;
            let _neg = (this.amount < 0); 

            _payment = {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        
        return _payment;
    }

    get Currency(){
        switch(this.currency){
            case 'EUR': return '€';
            case 'USD': return '$';
        }
        return this.currency;
    }
}
