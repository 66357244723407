import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { User } from './user';
import { Address } from './address';

export interface _UserAddress {
    status: string;
    user: number;
    address: number;
};

interface _UserAddressData extends _UserAddress {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class UserAddressData extends DataObject {
    protected _useraddress: _UserAddressData = {
        status: null,
        user: null,
        address: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._useraddress.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._useraddress.created;
    }

    get status(): string{
        return this._useraddress.status;
    }

    set status(value: string){
        if (this.patchValue(this._useraddress, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get user(): User {
        return this._children['user'] || null;
    }

    set user(value: User){
        if (this.SetChild('user', value, 'user')){
            this.ToUpdate = true;
        }        
    }

    get address(): Address {
        return this._children['address'] || null;
    }

    set address(value: Address) {
        if (this.SetChild('address', value, 'address')){
            this.ToUpdate = true;
        }        
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._useraddress.status,
            user: this._useraddress.user,
            address: this._useraddress.address
        };    
    }

    protected get Depend() {
        return {
            user: { item: this.user, relation_info: { to: 'address', by: 'user' } },        // this[by -> 'user'][to -> 'address'] => this
            address: { item: this.address, relation_info: { to: null, by: 'address' } }     // no relation to this in this[by -> 'address']
        };    
    }

    protected get Children(){
        let _children = [];

        if (this.address){
            _children.push(this.address);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_useraddress: _UserAddress){
        let _toUpdate = false;
        
        _toUpdate = this.patchValue(this._useraddress, 'status', _useraddress['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._useraddress, 'user', _useraddress['user']) || _toUpdate;
        _toUpdate = this.patchValue(this._useraddress, 'address', _useraddress['address']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_useraddress: _UserAddress){
        if (this._patchData(_useraddress)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._useraddress;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_useraddress: _UserAddress){
        this._patchData(_useraddress);
    }

    private _ddbb(info): _UserAddressData {
        let _useraddress: _UserAddressData = {
            objid: info['objid'],
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            user: info['user'] ? parseInt(info['user']) : null,
            address: info['address'] ? parseInt(info['address']) : null
        };

        return _useraddress;
    }

    protected _OnUpdate(info){
        let _useraddress = this._ddbb(info);
        this.patchValue(this._useraddress, 'objid', _useraddress['objid']);
        this.patchValue(this._useraddress, 'created', _useraddress['created']);
        this.DoPatchValues(_useraddress);
    }
}

export class UserAddress extends UserAddressData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('USERADDRESS', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): UserAddress {
        return this._Copy(store) as UserAddress;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
            this.user.DelAddress(this);
        }
        else {
            this.user.DoRefresh('USERADDRESS');
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this._useraddress.status == 'AC');
    }
}