import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { User } from './user';
import { QrCode } from './qrcode';
import { FCM } from './fcm';

export interface _Session {
    status: string;
    id: string;
    deviceid: string;
    user: number;
    qrcode: number;
};

interface _SessionData extends _Session {
    objid?: number;
    created?: Date;
    updated?: Date;
};

abstract class SessionData extends DataObject {

    protected _session: _SessionData = {
        status: null,
        id: null,
        deviceid: null,
        user: null,
        qrcode: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._session.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._session.created;
    }

    get status(): string {
        return this._session.status;
    }

    get id(): string {
        return this._session.id;
    }

    get deviceid(): string {
        return this._session.deviceid;
    }

    get user(): User {
        return this._children['user'] || null;
    }

    set user(value: User){
        this.SetChild('user', value, 'user');
    }

    get qrcode(): QrCode {
        return this._children['qrcode'] || null;
    }

    set qrcode(value: QrCode){
        this.SetChild('qrcode', value, 'qrcode');
    }
    

    get fcm() : FCM {
        if ((this._chldlist['fcm'] || []).length > 0){
            return this._chldlist['fcm'][0];
        }

        return null;
    }

    set fcm(value: FCM){
        if (this.fcm != value){
            if (this.fcm){
                this.DelChild('fcm', this.fcm, 'session');
            }

            this.AddChild('fcm', value, 'session');
        }
    }

    /****************************/
    /* SESSION OVERLOAD         */
    /****************************/

    get _uuid() {
        return this._session.id ? this._session.id : super._uuid;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {};      // Session data cannot be updated from client side
    }

    protected get Depend() {
        return { /* empty */ };
    }

    protected get Children(){
        return [];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_session: _Session){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._session, 'status', _session['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._session, 'id', _session['id']) || _toUpdate;
        _toUpdate = this.patchValue(this._session, 'deviceid', _session['deviceid']) || _toUpdate;
        _toUpdate = this.patchValue(this._session, 'user', _session['user']) || _toUpdate;
        _toUpdate = this.patchValue(this._session, 'qrcode', _session['qrcode']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_session: _Session){
        if (this._patchData(_session)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._session;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_session: _Session){
        this._patchData(_session);

        if (_session['user']){      // update children: 'user'
            let _objid = _session['user'].toString();
            this.SetChild('user', new User(_objid, this.data, this._objoptions), 'user')
        }
        else {
            this.SetChild('user', null, 'user');
        }
        
        if (_session['qrcode']){    // update children: 'qrcode'
            let _objid = _session['qrcode'].toString();        
            this.SetChild('qrcode', new QrCode(_objid, this.data, this._objoptions), 'qrcode')
        }
        else {
            this.SetChild('qrcode', null, 'qrcode');
        }
    }

    private _ddbb(info): _SessionData {
        let _session: _SessionData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            id: info['id'],
            deviceid: info['deviceid'],
            user: info['user'] ? parseInt(info['user']) : null,
            qrcode: info['qrcode'] ? parseInt(info['qrcode']) : null
        };
        return _session;
    }

    protected _OnUpdate(info){
        let _session = this._ddbb(info);
        this.patchValue(this._session, 'objid', _session['objid']);
        this.patchValue(this._session, 'created', _session['created']);
        this.patchValue(this._session, 'updated', _session['updated']);
        this.DoPatchValues(_session);

        if ('fcm' in info) {    // update children: 'fcm'  
            this.SetChildren <FCM> (info['fcm'], 'fcm', FCM, 'session');
        }
    }
}

export class Session extends SessionData {
    constructor(objid: string, data: dataService, id: string = null){
        super('SESSION', objid, data, {});
        if (id){
            this._session.id = id;
        }
    }

    Copy(store: Array<DataObject> = []) : Session {
        return this._Copy(store) as Session;
    }

    DoUpdate(){
        this._onUpdated.next();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get updated(): Date {
        return this._session.updated;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/
    
    private _lastcheck = null;
    private _nextcheck = null;

    private _getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }

    get IsActive(){     // only 'AC' sessions of the last 6 hours
        if ((this.status == 'AC') && (this.updated.getTime() > ((new Date()).getTime() - 6 * 3600 * 1000))) {
            let _active = (this.updated) && (this.updated.getTime() > ((new Date()).getTime() - 60 * 1000));
            if (!_active){      // maybe we didn't have updates on the session, update now
                if (this._nextcheck == null){
                    this._nextcheck = this._getRandomInt(60000, 62000);
                }

                let _timestamp = (new Date()).getTime();
                if (!this._lastcheck || ((this._lastcheck + this._nextcheck) < _timestamp)){
                    this._lastcheck = _timestamp;
                    this._nextcheck = null;     // generate new random number (in range 60 - 62 seconds)

                    this.ForceRefresh().then(   // force the entry update from the database
                    data => {
                        if (this.user){
                            this.user.DoRefresh('SESSION');
                        }
    
                        if (this.qrcode){
                            this.qrcode.DoRefresh('SESSION');
                        }    
                    });
                }
            }

            return _active;
        }
        return false;
    }
}

