import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { QrCode } from './qrcode';
import { DrawItem } from './draw';

export interface _PlaceArea {
    status: string,
    place: number;
    name: string;
    coord_w: number;
    coord_h: number;
};

interface _PlaceAreaData extends _PlaceArea {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

export abstract class PlaceAreaData extends DataObject {
    protected _area: _PlaceAreaData = {
        status: null,
        place: null,
        name: null,
        coord_w: null,
        coord_h: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._area.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date{
        return this._area.created;
    }

    get status() : string {
        return this._area.status;
    }

    set status(value: string){
        if (this.patchValue(this._area, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place(): Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get name() : string {
        return this._area.name;
    }

    set name(value: string){
        if (this.patchValue(this._area, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get width() : number {
        return this._area.coord_w;
    }

    set width(value: number){
        if (this.patchValue(this._area, 'coord_w', value)){
            this.ToUpdate = true;
        }
    }

    get height() : number {
        return this._area.coord_h;
    }

    set height(value: number){
        if (this.patchValue(this._area, 'coord_h', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddDrawable(child: DrawItem){
        this.AddChild('draw', child, 'area');
    }

    DelDrawable(child: DrawItem){
        this.DelChild('draw', child, 'area');
    }
    
    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get drawable() : Array <DrawItem> {
        return this._chldlist['draw'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._area.status,
            place: this._area.place,
            name: this._area.name,
            coord_w: this._area.coord_w,
            coord_h: this._area.coord_h
        };
    }

    protected get Depend(){
        return {
            place: { item: this.place, relation_info: { to: null, by: 'place' } }   // no relation to this in this[by -> 'place']
        };
    }

    protected get Children(){
        let _children = [];
        
        for(let _item of this.drawable){
            _children.push(_item)
        }

        return _children;        
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_area: _PlaceArea){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._area, 'status', _area['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._area, 'place', _area['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._area, 'name', _area['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._area, 'coord_w', _area['coord_w']) || _toUpdate;
        _toUpdate = this.patchValue(this._area, 'coord_h', _area['coord_h']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_area: _PlaceArea){
        if (this._patchData(_area)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._area;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_area: _PlaceArea){
        this._patchData(_area);

        if (_area['place']){     // update children: 'place'
            let _objid = _area['place'].toString();        
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }        
    }

    private _ddbb(info): _PlaceAreaData {
        let _area: _PlaceAreaData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            name: info['name'],
            coord_w: info['coord_w'] ? parseFloat(info['coord_w']) : 100,
            coord_h: info['coord_h'] ? parseFloat(info['coord_h']) : 100
        };
        return _area;
    }

    protected _OnUpdate(info){
        let _area = this._ddbb(info);
        this.patchValue(this._area, 'objid', _area['objid']);
        this.patchValue(this._area, 'created', _area['created']);
        this.DoPatchValues(_area);   

        if ('draw' in info){   // update children: 'draw'
            this.SetChildren <DrawItem> (info['draw'], 'draw', DrawItem, 'area');
        }        
    }
}

export class PlaceArea extends PlaceAreaData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PLACEAREA', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : PlaceArea {
        return this._Copy(store) as PlaceArea;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status == 'AC');
    }

    get vertices() : Array <DrawItem> {
        let _vertex = []

        for(let _drawable of this.drawable){
            if (_drawable.type == 'VERTEX') {
                _vertex.push(_drawable);
            }
        }

        return _vertex;
    }

    get tables() : Array <QrCode> {
        let _tables = [];

        for(let _qrcode of this.place.tables){
            if (_qrcode.IsValid && _qrcode.area == this){
                _tables.push(_qrcode);
            }
        }

        return _tables;  
    }

    get items() : Array <DrawItem> {
        let _items = [];

        for(let _drawable of this.drawable){
            if (['VERTEX', 'TABLE'].indexOf(_drawable.type) == -1) {
                _items.push(_drawable);
            }
        }

        return _items;
    }
}
