import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PrintDevice } from './printer';
import { PrintProductOption } from './printoption';
import { Product } from './product';

export interface _PrintProduct {
    status: string;
    printer: number;
    product: number;
};

interface _PrintProductData extends _PrintProduct {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class PrintProductData extends DataObject {
    protected _printerproduct: _PrintProductData = {
        status: null,
        printer: null,
        product: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._printerproduct.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._printerproduct.created;
    }

    get status(): string {
        return this._printerproduct.status;
    }

    set status(value: string) {
        if(this.patchValue(this._printerproduct, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get printer() : PrintDevice {
        return this._children['printer'] || null;
    }

    set printer(value: PrintDevice){
        if (this.SetChild('printer', value, 'printer')){
            this.ToUpdate = true;
        }
    }

    get product() : Product {
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: PrintProductOption){
        this.AddChild('options', child, 'product');
    }

    DelOption(child: PrintProductOption){
        this.DelChild('options', child, 'product');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <PrintProductOption> {
        return this._chldlist['options'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._printerproduct.status,
            printer: this._printerproduct.printer,
            product: this._printerproduct.product
        };
    }

    protected get Depend() {
        return {
            printer: { item: this.printer, relation_info: { to: 'products', by: 'printer' } },  // this[by -> 'printer'][to -> 'products'] => this
            product: { item: this.product, relation_info: { to: null, by: 'product' } }         // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.options){
            _children.push(_item);
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_printerproduct: _PrintProduct){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._printerproduct, 'status', _printerproduct['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._printerproduct, 'printer', _printerproduct['printer']) || _toUpdate;
        _toUpdate = this.patchValue(this._printerproduct, 'product', _printerproduct['product']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_printerproduct: _PrintProduct){
        if (this._patchData(_printerproduct)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._printerproduct;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_printerproduct: _PrintProduct){
        this._patchData(_printerproduct);
        
        if (_printerproduct['printer']){     // update children: 'printer'
            let _objid = _printerproduct['printer'].toString();
            this.SetChild('printer', new PrintDevice(_objid, this.data, this._objoptions), 'printer')
        }
        else {
            this.SetChild('printer', null, 'printer')
        }

        if (_printerproduct['product']){   // update children: 'product'
            let _objid = _printerproduct['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product')
        }
    }

    private _ddbb(info): _PrintProductData {
        let _printerproduct: _PrintProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            printer: info['printer'] ? parseInt(info['printer']) : null,
            product: info['product'] ? parseInt(info['product']) : null
        };
        return _printerproduct;
    }

    protected _OnUpdate(info){
        let _printerproduct = this._ddbb(info);
        this.patchValue(this._printerproduct, 'objid', _printerproduct['objid']);
        this.patchValue(this._printerproduct, 'created', _printerproduct['created']);
        this.DoPatchValues(_printerproduct);

        if (info['options']){     // update children: 'options'
            this.SetChildren <PrintProductOption> (info['options'], 'options', PrintProductOption, 'product');
        }
    }
}

export class PrintProduct extends PrintProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRINTERPRODUCT', objid, data, objoptions);
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []): PrintProduct {
        return this._Copy(store) as PrintProduct;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if (this.ToInsert){
                this.printer.DelProduct(this);
            }
            this.printer.ToUpdate = true;
        }                

        super.status = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status && (this.status != 'DE')) && (this.printer.status && (this.printer.status != 'DE'));
    }    

}