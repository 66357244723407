import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { CatalogObject } from './catalog';
import { Product } from './product';
import { ProductOption } from './productoption';
import { SelectOption } from './selectoption';
import { TicketProduct } from './ticketproduct';

export interface _ProductSelect {
    product: number;
    name: string;
    code: string;
    sort: number;
    photo: {
        url: string;
        b64: any;
    },
    description: string;
};

interface _ProductSelectData extends _ProductSelect {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ProductSelectData extends CatalogObject {
    protected _select: _ProductSelectData = {
        product: null,
        name: null,
        code: null,
        sort: 0,
        photo: {
            url: null,
            b64: null
        },
        description: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._select.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._select.created;
    }

    get product(): Product {
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._select.name;
    }

    set name(value: string){
        if (this.patchValue(this._select, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get code(): string {
        return this._select.code;
    }

    set code(value: string){
        if (this.patchValue(this._select, 'code', value)){
            this.ToUpdate = true;
        }
    }

    get sort(): number {
        return this._select.sort;
    }

    set sort(value: number){
        if (this.patchValue(this._select, 'sort', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string {
        return this._select.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._select.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }

    get base64(): any {
        return this._select.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._select.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string {
        return this._select.description;
    }

    set description(value: string){
        if (this.patchValue(this._select, 'description', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: SelectOption){
        this.AddChild('options', child, 'preselect');
    }

    DelOption(child: SelectOption){
        this.DelChild('options', child, 'preselect');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <SelectOption> {
        return this._chldlist['options'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                product: this._select.product,
                name: this._select.name,
                code: this._select.code,
                sort: this._select.sort,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                description: this._select.description
            };    
        })();
    }

    protected get Depend() {
        return {
            product: { item: this.product, relation_info: { to: 'selects', by: 'product' } }    // this[by -> 'product'][to -> 'selects'] => this
        };
    }

    protected get Children(){
        let _children = [];
        for(let _item of this.options){
            _children.push(_item)
        }
        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_select: _ProductSelect){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._select, 'product', _select['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._select, 'name', _select['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._select, 'code', _select['code']) || _toUpdate;
        _toUpdate = this.patchValue(this._select, 'sort', _select['sort']) || _toUpdate;
        _toUpdate = this.patchValue(this._select, 'photo', _select['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._select, 'description', _select['description']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_select: _ProductSelect){
        if (this._patchData(_select)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._select;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_select: _ProductSelect){
        this._patchData(_select);

        if (_select['product']){       // update children 'product'
            let _objid = _select['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product');
        }
        else {
            this.SetChild('product', null, 'product');
        }
    }

    private _ddbb(info): _ProductSelectData {
        let _select: _ProductSelectData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            product: info['product'] ? parseInt(info['product']) : null,
            name: info['name'],
            code: info['code'],
            sort: info['sort'] ? parseInt(info['sort']) : 0,
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },
            description: info['description']
        };
        return _select;
    }

    protected _OnUpdate(info){
        let _select = this._ddbb(info);
        this.patchValue(this._select, 'objid', _select['objid']);
        this.patchValue(this._select, 'created', _select['created']);
        this.DoPatchValues(_select);

        if (info['options']){       // update children 'options'
            this.SetChildren <SelectOption> (info['options'], 'options', SelectOption, 'preselect');
        }
    }
}

export class ProductSelect extends ProductSelectData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRESELECT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ProductSelect {
        return this._Copy(store) as ProductSelect;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/
    
    get isgroup() : boolean {
        return false;
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.product.name);
    }

    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    get parent(): Product {
        return this.product;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){      // all the product options exist (are not deleted)
        if (!this.product || !this.product.IsValid){
            return false;
        }

        let _deleted = this.options.some(
        (option) => {
            return (option.status == 'AC') && !option.option.IsValid
        });
        
        return !_deleted;    
    }

    get IsActive(){     // all the direct options are active (otherwise will be deleted)
        let _isactive = true;

        if (_isactive){     // all options are active
            _isactive = this.options.every(
            (option) => {
                return !option.IsValid || option.IsActive;
            });        
        }

        if (_isactive){     // all categories are available
            for(let _option of this.options){
                _isactive = !_option.IsValid || this.product.categories.some(
                (category) => {
                    return (category == _option.option.category);
                });
            }
        }

        return _isactive;    
    }

    get IsAvailable(){  // the direct is available to select
        let _available = this.IsValid && this.product.IsAvailable;

        let _options = [];
        for (let _option of this.options){
            if (_option.IsValid){
                _options.push(_option)
            }
        }

        if (_available){    // all options are available
            _available = _options.every(
            (option) => {
                return option.option.IsAvailable
            });
        }

        if (_available){    // all categories are available
            for(let _option of _options){
                _available = this.product.categories.some(
                category => {
                    return (category == _option.option.category);
                });
            }
        }

        return _available;
    }

    get IsCompleted(){  // the direct options are completed (make a choice) 
        let _selectoptions: Array <ProductOption> = [];

        for(let option of this.options){
            if (option.IsValid && option.IsActive){
                _selectoptions.push(option.option);
            }
        }

        for (let _category of this.product.categories){
            let _valid = false;

            if (!_valid) {  // check if category has available options
                _valid = (_category.options.length == 0);
                _valid ||= _category.options.every(
                (option) => {
                    return !option.IsAvailable;
                });
            }

            if (!_valid) {  // check if category has active depends
                _valid = (_category.depends.length != 0);
                _valid &&= !_category.depends.some(
                (depend) => {
                    return (_selectoptions.indexOf(depend.option) != -1);
                });
            }

            if (!_valid){
                if (_category.type == 'SINGLE'){
                    _valid = _category.options.some(
                    (option) => {
                        return (_selectoptions.indexOf(option) != -1);
                    });
                }

                if (_category.type == 'MULTIPLE'){
                    _valid = true;
                }

                if (_category.type == 'FIXED'){
                    let _count = 0;
                    for(let option of _category.options){
                        _count += (_selectoptions.indexOf(option) == -1) ? 0 : 1;
                    }
                    _valid = (_count >= _category.fxmin) && (_count <= _category.fxmax);
                }
            }

            if (!_valid){
                return false;   // al least one category is not valid
            }
        }

        return true;
    }

    get Price(){
        let _price = this.product ? Number(this.product.price || 0) : 0;
        for(let _option of this.options){
            if (_option.IsValid){
                _price += Number(_option.option.price);
            }
        }
        _price = Math.round(_price * 100) / 100;        
        
        let _parts = (Math.abs(_price)).toString().split('.');

        let _int = _parts[0];
        let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
        let _str = _price.toFixed(2);
        let _neg = (_price < 0); 

        return {
            Int: _int,
            Dec: _dec,
            Str: _str,
            Neg: _neg
        }    
    }

    IsChosen(ticketproduct: TicketProduct){
        let _options = [];
        for (let _option of this.options){
            if (_option.IsValid){
                _options.push(_option);
            }
        }

        let _chosen = _options.length == ticketproduct.options.length;
        if (_chosen){
            _chosen = _options.every(
            (_selectoption) => {
                for(let _ticketoption of ticketproduct.options){
                    if (_ticketoption.option == _selectoption.option){
                        return true;    // select option found in ticketproduct options
                    }
                }
                return false;
            });
        }
        return _chosen;
    }

    HasSelection(option: ProductOption){
        let _selectoption = null;
        for(let _option of this.options){
            if (_option.IsValid && (_option.option == option)){
                _selectoption = _option;
            }
        }
        return _selectoption;
    }

    AddSelection(option: ProductOption){
        let _selectoption = this.HasSelection(option)
        if (!_selectoption){
            _selectoption = new SelectOption(null, this.data);
            if (_selectoption){
                _selectoption.select = this;
                _selectoption.option = option;
                _selectoption.status = 'AC';
            }
            this.AddOption(_selectoption);
        }
        else {
            _selectoption.status = 'AC';
        }
    }

    DelSelection(option: ProductOption){
        let _selectoption = this.HasSelection(option);
        if (_selectoption){
            this.DelOption(_selectoption);
        }
    }
}


