import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { User } from './user';
import { Place } from './place';

export interface _Waiter {
    status: string;
    place: number;
    photo: {
        url: string;
        b64: any;
    },
    user: number;
    alias: string,
    allow: string,
    email: string;
};

interface _WaiterData extends _Waiter {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class WaiterData extends DataObject {
    protected _waiter: _WaiterData = {
        status: null,
        place: null,
        user: null,
        photo: {
            url: null,
            b64: null
        },
        alias: null,
        allow: null,
        email: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._waiter.created = new Date();
    }

    /****************************/
    /* STAFF PERMISSION FLAGS   */
    /****************************/

    protected AllowFlags = Object.freeze({
        StaffManage: 0,     // allow to create or edit staff members
        OfferManage: 1,     // allow to create or edit offers
        ProductManage: 2,   // allow to create or edit products
        TableManage: 3,     // allow to create or edit tables
        ExtraManage: 4,     // allow to create or edit table suplements
        PlaceManage: 5,     // allow to edit place details
        LicenceManage: 6,   // allow to pay application license
        CanCompliment: 7,   // allow to compliment on ticket items 
        CanAvailable: 8,    // allow to set an item as available / unavailable
        POSManage: 9,       // allow to configure POS details (as printers)
        ReportAccess: 10,   // allow to review place reports
        TillManage: 11,     // allow to control/open the till 
        CanCancel: 12,      // allow to cancel / reject tickets
        CanModify: 14,      // allow to modify created tickets
        CanReturn: 15,      // allow to return paid products on tickets
        CanReopen: 16       // allow to reopen closed tickets
    });

    protected get allow(): Array<any> {
        let _array = Object.keys(this.AllowFlags).map(() => false);

        if (this._waiter.allow){
            let _flags = Array.from(this._waiter.allow);
            for(let i=0; i < _flags.length; i++){
                _array[i] = (_flags[i] == '1');
            }        
        }

        return _array;
    }

    protected set allow(value: Array<any>) {
        let _array = [];
        for(let i=0; i < value.length; i++){
            _array[i] = (value[i] === true) ? '1' : '0';
        }

        let _flags = _array.join(''); 
        if(this.patchValue(this._waiter, 'allow', _flags)){
            this.ToUpdate = true;
        }
    }

    protected _setFlagValue(flag, value){
        let _flags = this.allow;
        _flags[flag] = value;
        this.allow = _flags;        
    }

    get AllowStaffManage(){
        return this.allow[this.AllowFlags.StaffManage];
    }

    set AllowStaffManage(value: boolean){
        this._setFlagValue(this.AllowFlags.StaffManage, value);
    }

    get AllowOfferManage(){
        return this.allow[this.AllowFlags.OfferManage];
    }

    set AllowOfferManage(value: boolean){
        this._setFlagValue(this.AllowFlags.OfferManage, value);
    }

    get AllowProductManage(){
        return this.allow[this.AllowFlags.ProductManage];
    }

    set AllowProductManage(value: boolean){
        this._setFlagValue(this.AllowFlags.ProductManage, value);
    }

    get AllowTableManage(){
        return this.allow[this.AllowFlags.TableManage];
    }

    set AllowTableManage(value: boolean){
        this._setFlagValue(this.AllowFlags.TableManage, value);
    }

    get AllowExtraManage(){
        return this.allow[this.AllowFlags.ExtraManage];
    }

    set AllowExtraManage(value: boolean){
        this._setFlagValue(this.AllowFlags.ExtraManage, value);
    }

    get AllowPlaceManage(){
        return this.allow[this.AllowFlags.PlaceManage];
    }

    set AllowPlaceManage(value: boolean){
        this._setFlagValue(this.AllowFlags.PlaceManage, value);
    }

    get AllowLicenceManage(){
        return this.allow[this.AllowFlags.LicenceManage];
    }

    set AllowLicenceManage(value: boolean){
        this._setFlagValue(this.AllowFlags.LicenceManage, value);
    }

    get AllowCompliment(){
        return this.allow[this.AllowFlags.CanCompliment];
    }

    set AllowCompliment(value: boolean){
        this._setFlagValue(this.AllowFlags.CanCompliment, value);
    }

    get AllowAvailability(){
        return this.allow[this.AllowFlags.CanAvailable];
    }

    set AllowAvailability(value: boolean){
        this._setFlagValue(this.AllowFlags.CanAvailable, value);
    }

    get AllowPOSManage(){
        return this.allow[this.AllowFlags.POSManage];
    }

    set AllowPOSManage(value: boolean){
        this._setFlagValue(this.AllowFlags.POSManage, value);
    }

    get AllowReports(){
        return this.allow[this.AllowFlags.ReportAccess];
    }

    set AllowReports(value: boolean){
        this._setFlagValue(this.AllowFlags.ReportAccess, value);
    }

    get AllowTillManage(){
        return this.allow[this.AllowFlags.TillManage];
    }

    set AllowTillManage(value: boolean){
        this._setFlagValue(this.AllowFlags.TillManage, value);
    }

    get AllowTicketReopen(){
        return this.allow[this.AllowFlags.CanReopen];
    }

    set AllowTicketReopen(value: boolean){
        this._setFlagValue(this.AllowFlags.CanReopen, value);
    }

    get AllowTicketCancel(){
        return this.allow[this.AllowFlags.CanCancel];
    }

    set AllowTicketCancel(value: boolean){
        this._setFlagValue(this.AllowFlags.CanCancel, value);
    }

    get AllowTicketUpdate(){
        return this.allow[this.AllowFlags.CanModify];
    }

    set AllowTicketUpdate(value: boolean){
        this._setFlagValue(this.AllowFlags.CanModify, value);
    }

    get AllowTicketReturn(){
        return this.allow[this.AllowFlags.CanReturn];
    }

    set AllowTicketReturn(value: boolean){
        this._setFlagValue(this.AllowFlags.CanReturn, value);
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._waiter.created;
    }

    get status(): string{
        return this._waiter.status;
    }

    set status(value: string){
        if (this.patchValue(this._waiter, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place) {
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._waiter.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._waiter.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }
   
    get base64(): any {
        return this._waiter.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._waiter.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get alias(): string{
        return this._waiter.alias;
    }

    set alias(value: string){
        if (this.patchValue(this._waiter, 'alias', value)){
            this.ToUpdate = true;
        }
    }

    get user() : User {
        return this._children['user'] || null;
    }

    set user(value: User) {
        if (this.SetChild('user', value, 'user')){
            this.ToUpdate = true;
        }
    }

    get email(): string {
        return this._waiter.email;
    }

    set email(value: string) {
        if (this.patchValue(this._waiter, 'email', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                place: this._waiter.place,
                status: this._waiter.status,
                user: this._waiter.user,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                alias: this._waiter.alias,
                allow: this._waiter.allow,
                email: this._waiter.email
            };    
        })();
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'waiters', by: 'place' } },     // this[by -> 'place'][to -> 'waiters'] => this
            user: { item: this.user, relation_info: { to: 'waiter', by: 'user' } }          // this[by -> 'user'][to -> 'waiter'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_waiter: _Waiter){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._waiter, 'status', _waiter['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'place', _waiter['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'photo', _waiter['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'user', _waiter['user']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'alias', _waiter['alias']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'allow', _waiter['allow']) || _toUpdate;
        _toUpdate = this.patchValue(this._waiter, 'email', _waiter['email']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_waiter: _Waiter){
        if (this._patchData(_waiter)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._waiter;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_waiter: _Waiter){
        this._patchData(_waiter)

        if (_waiter['user']){      // update children: 'user'
            let _objid = _waiter['user'].toString();
            this.SetChild('user', new User(_objid, this.data, this._objoptions), 'user');
        }
        else {
            this.SetChild('user', null, 'user');
        }

        if (_waiter['place']){      // update children: 'place'
            let _objid = _waiter['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place');
        }
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _WaiterData {
        let _waiter: _WaiterData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            user: info['user'] ? parseInt(info['user']) : null,
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },        
            alias: info['alias'],
            allow: info['allow'],    
            email: info['email']
        };

        return _waiter;
    }

    protected _OnUpdate(info){
        let _waiter = this._ddbb(info);
        this.patchValue(this._waiter, 'objid', _waiter['objid']);
        this.patchValue(this._waiter, 'created', _waiter['created']);
        this.DoPatchValues(_waiter);
    }
}

export class Waiter extends WaiterData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('STAFF', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Waiter {
        return this._Copy(store) as Waiter;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
            this.place.DelWaiter(this);
        }
        else {
            this.place.DoRefresh('STAFF');
        }
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.user.fullname);
    }

    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (['PA', 'AC'].includes(this.status));
    }

    get IsActive(){
        return (this.status == 'AC');
    }

    get IsOnline(){
        return this.user.sessions.some(
        (session) => {
            return session.IsActive;
        });
    }
}
