import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Offer } from './offer';
import { Ticket } from './ticket';

export interface _TicketOffer {
    status: string,
    offer: number,
    ticket: number,
    fixed: boolean,
    price: number,
    charge: number,
    refund: number
};

interface _TicketOfferData extends _TicketOffer {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketOfferData extends DataObject {
    protected _offer: _TicketOfferData = {
        status: null,
        offer: null,
        ticket: null,
        fixed: false,
        price: null,
        charge: null,
        refund: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._offer.created = new Date();
    }

    /****************************/
    /* BEHAVIOUR UPDATE         */
    /****************************/

    UpRefresh(){    // do not refresh offers when updated
        for (let _child in this._children){
            if ([ 'offer' ].indexOf(_child) != -1){
                continue;
            }

            let _parent = this._children[_child];
            if (_parent && _parent instanceof DataObject){
                _parent.DoRefresh('TICKETOFFER');
            }
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._offer.created;
    }

    get status(): string {
        return this._offer.status;
    }

    set status(value: string){
        if (this.patchValue(this._offer, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get offer(): Offer {
        return this._children['offer'] || null;
    }

    set offer(value: Offer){
        if (this.SetChild('offer', value, 'offer')){
            this.ToUpdate = true;
        }
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get charge(): number {
        return this._offer.charge;
    }

    set charge(value: number){
        if (this.patchValue(this._offer, 'charge', value)){
            this.ToUpdate = true;
        }
    }

    get fixed(): boolean {
        return this._offer.fixed;
    }

    set fixed(value: boolean){
        if (this.patchValue(this._offer, 'fixed', value)){
            this.ToUpdate = true;
        }
    }

    get price(): number {
        return this._offer.price;
    }

    set price(value: number){
        if (this.patchValue(this._offer, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get refund(): number {
        return this._offer.refund;
    }

    set refund(value: number){
        if (this.patchValue(this._offer, 'refund', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._offer.status,
            offer: this._offer.offer,
            ticket: this._offer.ticket,
            charge: this._offer.charge,
            fixed: this._offer.fixed ? '1':'0',
            price: this._offer.price,
            refund: this._offer.refund
        };
    }
    
    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'offers', by: 'ticket' } },   // this[by -> 'ticket'][to -> 'offers'] => this
            offer: { item: this.offer, relation_info: { to: null, by: 'offer' } }           // no relation to this in this[by -> 'offer']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_offer: _TicketOffer){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._offer, 'status', _offer['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'offer', _offer['offer']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'ticket', _offer['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'fixed', _offer['fixed']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'price', _offer['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'charge', _offer['charge']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'refund', _offer['refund']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_offer: _TicketOffer){
        if (this._patchData(_offer)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._offer;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_offer: _TicketOffer){
        this._patchData(_offer);

        if (_offer['offer']){     // update children: 'offer'
            let _objid = _offer['offer'].toString();
            this.SetChild('offer', new Offer(_objid, this.data, this._objoptions), 'offer');
        }
        else {
            this.SetChild('offer', null), 'offer';
        }

        if (_offer['ticket']){    // update children: 'ticket'
            let _objid = _offer['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket');
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }
    }

    private _ddbb(info): _TicketOfferData {
        let _offer: _TicketOfferData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            offer: info['offer'] ? parseInt(info['offer']) : null,
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            charge: info['charge'] ? parseFloat(info['charge']) : null,
            fixed: (info['fixed'] == '1'),
            price: info['price'] ? parseFloat(info['price']) : null,
            refund: info['refund'] ? parseFloat(info['refund']) : null
        };
        return _offer;
    }

    protected _OnUpdate(info){
        let _offer = this._ddbb(info);
        this.patchValue(this._offer, 'objid', _offer['objid']);
        this.patchValue(this._offer, 'created', _offer['created']);
        this.DoPatchValues(_offer);
    }
}

export class TicketOffer extends TicketOfferData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETOFFER', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketOffer {
        return this._Copy(store) as TicketOffer;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
            this.ticket.DelOffer(this);
        }
        else {
            this.ticket.DoRefresh('TICKETOFFER');
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        if (!this.status){
            return false;
        }
        
        return (this.status != 'DE');
    }

    get OfferPrice(){
        if (this.IsValid && ((this.ticket.IsValid) || (this.ticket.status == 'PR'))){
            return this.offer.price;
        }

        return 0;
    }

    get TotalPrice(){
        if (this.IsValid && ((this.ticket.IsValid) || (this.ticket.status == 'PR'))){
            let _charge = 0;

            for(let _ticketproduct of this.ticket.products){
                if ((_ticketproduct.status == 'AC') && (_ticketproduct.offer == this)){
                    _charge += _ticketproduct.ChargePrice;
                }
            }    
    
            return Math.round(_charge * 100) / 100;    
        }

        return 0;
    }

    get FixedPrice(){
        if (this.IsValid && this.fixed && ((this.ticket.IsValid) || (this.ticket.status == 'PR'))){
            let _charge = this.price;  

            if (_charge > 0){   // if it is on the house do not include product options
                for(let _ticketproduct of this.ticket.products){
                    if ((_ticketproduct.status == 'AC') && (_ticketproduct.offer == this)){
                        _charge += _ticketproduct.OfferPrice;
                    }
                }        
            }
    
            return Math.round(_charge * 100) / 100;                
        }

        return 0;
    }

    get ChargePrice() {
        if (this.fixed){
            return this.FixedPrice;
        }
        else {
            return this.TotalPrice;
        }
    }

    get RefundPrice() {
        if (!this.IsValid || !this.ticket.IsPaid) {
            return 0;   // no refund if its not paid
        }

        let _refund = 0;

        for(let _ticketproduct of this.ticket.products){
            if ((_ticketproduct.status == 'UN') && (_ticketproduct.offer == this)){
                _refund += _ticketproduct.charge;
            }
        }    

        return Math.round(_refund * 100) / 100;
    }
}


