import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PlaceArea } from './placearea';

import { Subject } from 'rxjs';

export interface _DrawInfo {
    status: string;
    type: string;
    area: number;
    shape: string,
    custom: any,
    sort: number;
    coord_x: number,
    coord_y: number,
    coord_w: number,
    coord_h: number,
    coord_r: number,
    render: boolean
};

interface _DrawData extends _DrawInfo {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class DrawData extends DataObject {
    protected _draw: _DrawData = {
        status: null,
        type: null,
        area: null,
        shape: null,
        custom: null,
        sort: null,
        coord_x: null,
        coord_y: null,
        coord_w: null,
        coord_h: null,
        coord_r: null,
        render: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._draw.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._draw.created;
    }

    get status(): string {
        return this._draw.status;
    }

    set status(value: string){
        if (this.patchValue(this._draw, 'status', value)){
            this.ToUpdate = true;
        }          
    }

    get area(): PlaceArea{
        return this._children['area'] || null;
    }

    set area(value: PlaceArea){
        if (this.SetChild('area', value, 'area')){
            this.ToUpdate = true;
        }
    }

    get custom(): any{
        return this._draw.custom; 
    }

    set custom(value: any){
        if (this.patchValue(this._draw, 'custom', value)){
            this.ToUpdate = true;
        }          
    }

    get type(): string{
        return this._draw.type;
    }

    set type(value: string) {
        if (this.patchValue(this._draw, 'type', value)){
            this.ToUpdate = true;
        }   
    }

    get shape(): string{
        return this._draw.shape;
    }

    set shape(value: string) {
        if (this.patchValue(this._draw, 'shape', value)){
            this.ToUpdate = true;
        }   
    }

    get sort(): number{
        return this._draw.sort;
    }

    set sort(value: number) {
        if (this.patchValue(this._draw, 'sort', value)){
            this.ToUpdate = true;
        }   
    }

    get coordinates(): any {
        return {
            x: this._draw.coord_x,
            y: this._draw.coord_y,
            w: this._draw.coord_w,
            h: this._draw.coord_h,
            r: this._draw.coord_r
        }
    }

    set coordinates(value: any){
        if (this.patchValue(this._draw, 'coord_x', Math.round(value.x))){
            this.ToUpdate = true;
        }        

        if (this.patchValue(this._draw, 'coord_y', Math.round(value.y))){
            this.ToUpdate = true;
        }        

        if (this.patchValue(this._draw, 'coord_w', Math.round(value.w))){
            this.ToUpdate = true;
        }        

        if (this.patchValue(this._draw, 'coord_h', Math.round(value.h))){
            this.ToUpdate = true;
        }       
        
        if (this.patchValue(this._draw, 'coord_r', Math.round(value.r))){
            this.ToUpdate = true;
        }           
    }    

    get render(): boolean {
        return this._draw.render || false;
    }

    set render(value: boolean){
        this._draw.render = value;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change(){
        return {
            status: this._draw.status,
            type: this._draw.type,
            area: this._draw.area,
            custom: this._draw.custom ? JSON.stringify(this._draw.custom) : null,
            shape: this._draw.shape,
            sort: this._draw.sort,
            coord_x: this._draw.coord_x,
            coord_y: this._draw.coord_y,
            coord_w: this._draw.coord_w,
            coord_h: this._draw.coord_h,
            coord_r: this._draw.coord_r
        };
    }

    protected get Depend() {
        return {
            area: { item: this.area, relation_info: { to: 'draw', by: 'area' } }    // this[by -> 'area'][to -> 'draw'] => this
        };
    }

    protected get Children() {
        return [ /* empty */ ];
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_draw: _DrawInfo){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._draw, 'status', _draw['status']) || _toUpdate;        
        _toUpdate = this.patchValue(this._draw, 'type', _draw['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'area', _draw['area']) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'shape', _draw['shape']) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'custom', _draw['custom']) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'sort', _draw['sort']) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'coord_x', Math.round(_draw['coord_x'])) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'coord_y', Math.round(_draw['coord_y'])) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'coord_w', Math.round(_draw['coord_w'])) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'coord_h', Math.round(_draw['coord_h'])) || _toUpdate;
        _toUpdate = this.patchValue(this._draw, 'coord_r', Math.round(_draw['coord_r'])) || _toUpdate;        
        _toUpdate = this.patchValue(this._draw, 'render', _draw['render']) || _toUpdate;        


        return _toUpdate;
    }
    
    set Data(_draw: _DrawInfo){
        if (this._patchData(_draw)){
            this.ToUpdate = true;
        }

        this.render = _draw.render;
    }

    get Info(){
        return this._draw;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _DrawData {
        let _draw: _DrawData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            type: info['type'],
            area: info['area'] ? parseInt(info['area']) : null,
            shape: info['shape'],
            custom: info['custom'] ? JSON.parse(info['custom']) : null,
            sort: info['sort'] ? parseInt(info['sort']): 0,
            coord_x: info['coord_x'] ? parseFloat(info['coord_x']) : 0,
            coord_y: info['coord_y'] ? parseFloat(info['coord_y']) : 0,
            coord_w: info['coord_w'] ? parseFloat(info['coord_w']) : 0,
            coord_h: info['coord_h'] ? parseFloat(info['coord_h']) : 0,
            coord_r: info['coord_r'] ? parseFloat(info['coord_r']) : 0,
            
            render: false   // not provided in database
        };
        return _draw;
    }

    private DoPatchValues(_draw: _DrawInfo){
        this._patchData(_draw);

        if (_draw['area']){     // update children: 'area'
            let _objid = _draw['area'].toString();
            this.SetChild('area', new PlaceArea(_objid, this.data, this._objoptions), 'area');
        }  
        else {
            this.SetChild('area', null, 'area');
        }
    }

    protected _OnUpdate(info){
        let _draw = this._ddbb(info);
        this.patchValue(this._draw, 'objid', _draw['objid']);
        this.patchValue(this._draw, 'created', _draw['created']);
        this.DoPatchValues(_draw);
    }
}

export class DrawItem extends DrawData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('DRAWITEM', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): DrawItem {
        return this._Copy(store) as DrawItem;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.area){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.area.DelDrawable(this);
            }
            else {
                this.area.DoRefresh('DRAWITEM');
            }    
        }
    }

    /****************************/
    /* DRAW EVENTS              */
    /****************************/

    private _onClick = new Subject<any>();
    public OnClick = this._onClick.asObservable();
    DoClick(){
        this._onClick.next();
    }

    private _onDrag = new Subject<any>();
    public OnDrag = this._onDrag.asObservable();
    DoDrag(){
        this._onDrag.next();
    }

    private _onDrop = new Subject<any>();
    public OnDrop = this._onDrop.asObservable();
    DoDrop(){
        this._onDrop.next();
    }    

    private _onMove = new Subject<any>();
    public OnMove = this._onMove.asObservable();
    DoMove(){
        this._onMove.next();
    }  

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        return (this.status == 'AC') && (this.area != null) && (this.area.IsValid);
    }

    private _clickbox = null;
    get clickbox(){
        if (!this._clickbox){
            let _tw = this.coordinates.w;
            let _th = this.coordinates.h;
            let _tr = this.coordinates.r;
    
            switch(this.type){
                case 'VERTEX':
                    _tw = 15;
                    _th = 15;        
                    break;
    
                case 'DOOR':
                    _th = 15;
                    break;
    
                case 'WINDOW':
                    _th = 15;
                    break;
            }
    
            let _tx = this.coordinates.x - (_tw/2);
            let _ty = this.coordinates.y - (_th/2);
    
            this._clickbox = [ _tx, _ty, _tw, _th ];
            if (_tr != 0){
                this._clickbox = this._rotatedBox(_tx, _ty, _tw, _th, _tr);
            }
        }

        let _cb = { x: 0, y: 0, w: 0, h: 0 }
        
        if (this._clickbox){
            _cb.x = this._clickbox[0];
            _cb.y = this._clickbox[1];
            _cb.w = this._clickbox[2];
            _cb.h = this._clickbox[3];
        }

        return _cb;
    }

    private _rotatedBox(_tx, _ty, _tw, _th, _tr){
        if (_tr != 0){
            _tr = _tr * (Math.PI / 180);
            
            let _sin = Math.sin(_tr);
            let _cos = Math.cos(_tr);

            // rotated object box vertices
            let _box = [{   
                x: _tx + (0 - (_tw/2) * _cos) - (0 - (_th/2) * _sin),
                y: _ty + (0 - (_tw/2) * _sin) + (0 - (_th/2) * _cos) 
            }, {
                x: _tx + (0 + (_tw/2) * _cos) - (0 - (_th/2) * _sin),
                y: _ty + (0 + (_tw/2) * _sin) + (0 - (_th/2) * _cos)
            }, {
                x: _tx + (0 - (_tw/2) * _cos) - (0 + (_th/2) * _sin),
                y: _ty + (0 - (_tw/2) * _sin) + (0 + (_th/2) * _cos) 
            }, {
                x: _tx + (0 + (_tw/2) * _cos) - (0 + (_th/2) * _sin), 
                y: _ty + (0 + (_tw/2) * _sin) + (0 + (_th/2) * _cos) 
            }]; 

            // rotated object box coordinates (x, y, w, h)
            let _tx1 = Math.min(_box[0].x, _box[1].x, _box[2].x, _box[3].x);
            let _tx2 = Math.max(_box[0].x, _box[1].x, _box[2].x, _box[3].x);
            let _ty1 = Math.min(_box[0].y, _box[1].y, _box[2].y, _box[3].y);
            let _ty2 = Math.max(_box[0].y, _box[1].y, _box[2].y, _box[3].y);

            [ _tx, _ty, _tw, _th ] = [ _tx1 + _tw/2, _ty1 + _th/2, (_tx2 - _tx1), (_ty2 - _ty1) ];
        }

        return [ _tx, _ty, _tw, _th ]
    }
    
    get coordinates(): any {
        return super.coordinates;
    }
   
    set coordinates(value: any){
        this._clickbox = null;  // must be recalculated
        super.coordinates = value;
    }
}

