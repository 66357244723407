import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';

export interface _Business {
    place: number;
    status: string;
    name: string;
    taxid: string;
    address: string;
    email: string;
    postal: string;
    region: string;
    last: Date;
};

interface _BusinessData extends _Business {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

export abstract class BusinessData extends DataObject {
    protected _business: _BusinessData = {
        place: null,
        status: null,
        name: null,
        taxid: null,
        address: null,
        email: null,
        postal: null,
        region: null,
        last: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get place(): Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get status() : string {
        return this._business.status;
    }

    set status(value: string){
        if (this.patchValue(this._business, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get name() : string {
        return this._business.name;
    }

    set name(value: string){
        if (this.patchValue(this._business, 'name', value)){
            this.ToUpdate = true;
        }
    }
    
    get taxid() : string {
        return this._business.taxid;
    }

    set taxid(value: string){
        if (this.patchValue(this._business, 'taxid', value)){
            this.ToUpdate = true;
        }
    }
    
    get address() : string {
        return this._business.address;
    }

    set address(value: string){
        if (this.patchValue(this._business, 'address', value)){
            this.ToUpdate = true;
        }
    }    

    get email(): string {
        return this._business.email;
    }

    set email(value: string){
        if (this.patchValue(this._business, 'email', value)){
            this.ToUpdate = true;
        }
    }

    get postalcode(): string {
        return this._business.postal;
    }

    set postalcode(value: string) {
        if (this.patchValue(this._business, 'postal', value)){
            this.ToUpdate = true;
        }
    }

    get region(): string {
        return this._business.region;
    }

    set region(value: string) {
        if (this.patchValue(this._business, 'region', value)){
            this.ToUpdate = true;
        }
    }

    get last(): Date {
        return this._business.last;
    }

    set last(value: Date){
        if (this.patchValue(this._business, 'last', value)){
            this.ToUpdate = true;
        }        
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._business.place,
            status: this._business.status,
            name: this._business.name,
            taxid: this._business.taxid,
            address: this._business.address,
            email: this._business.email,
            postal: this._business.postal,
            region: this._business.region,
            last: this._business.last ? this.dateStrToMysql(this._business.last) : null
        };
    }

    protected get Depend(){
        return {
            place: { item: this.place, relation_info: { to: 'clients', by: 'place' } }  // this[by -> 'place'][to -> 'clients'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_business: _Business){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._business, 'place', _business['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'status', _business['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'name', _business['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'taxid', _business['taxid']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'address', _business['address']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'email', _business['email']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'postal', _business['postal']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'region', _business['region']) || _toUpdate;
        _toUpdate = this.patchValue(this._business, 'last', _business['last']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_business: _Business){
        if (this._patchData(_business)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._business;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_business: _Business){
        this._patchData(_business);

        if (_business['place']){     // update children: 'place'
            let _objid = _business['place'].toString();        
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }        
    }

    private _ddbb(info): _BusinessData {
        let _business: _BusinessData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            place: info['place'] ? parseInt(info['place']) : null,
            status: info['status'],
            name: info['name'],
            taxid: info['taxid'],
            address: info['address'],
            email: info['email'],
            postal: info['postal'],
            region: info['region'],
            last: info['last'] ? new Date(Date.parse(this.mysqlToDateStr(info['last']))) : null
        };
        return _business;
    }

    protected _OnUpdate(info){
        let _business = this._ddbb(info);
        this.patchValue(this._business, 'objid', _business['objid']);
        this.patchValue(this._business, 'created', _business['created']);
        this.DoPatchValues(_business);   
    }
}

export class Business extends BusinessData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('INVOICEBUSINESS', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : Business {
        return this._Copy(store) as Business;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        return this.status && (this.status == 'AC');
    }

    get IsPayAccount() {
        let _accounts = [];
        for(let _account of this.place.payaccounts){
            if (_account.IsValid){
                _accounts.push(_account.client);
            }
        }

        return _accounts.includes(this);
    }
}
