import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PrintProduct } from './printproduct';
import { ProductOption } from './productoption';

export interface _PrintProductOption {
    status: string;
    product: number;
    prodopt: number;
};

interface _PrintProductOptionData extends _PrintProductOption {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class PrintProductOptionData extends DataObject {
    protected _option: _PrintProductOptionData = {
        status: null,
        product: null,
        prodopt: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._option.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._option.created;
    }

    get status(): string {
        return this._option.status;
    }

    set status(value: string) {
        if(this.patchValue(this._option, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get product(): PrintProduct {
        return this._children['product'] || null;
    }

    set product(value: PrintProduct ){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get prodopt() : ProductOption {
        return this._children['prodopt'] || null;
    }

    set prodopt(value: ProductOption) {
        if (this.SetChild('prodopt', value, 'prodopt')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._option.status, 
            product: this._option.product,
            prodopt: this._option.prodopt
        };
    }

    protected get Depend() {
        return {
            product: { item: this.product, relation_info: { to: null, by: 'product' } },    // no relation to this in this[by -> 'product']
            prodopt: { item: this.prodopt, relation_info: { to: null, by: 'prodopt' } }     // no relation to this in this[by -> 'prodopt']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_option: _PrintProductOption){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._option, 'status', _option['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'product', _option['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'prodopt', _option['prodopt']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_offerproduct: _PrintProductOption){
        if (this._patchData(_offerproduct)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._option;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_option: _PrintProductOption){
        this._patchData(_option);

        if (_option['product']){     // update children: 'product'
            let _objid = _option['product'].toString();
            this.SetChild('product', new PrintProduct(_objid, this.data, this._objoptions), 'product');
        }
        else {
            this.SetChild('product', null, 'product');
        }

        if (_option['prodopt']){     // update children: 'prodopt'
            let _objid = _option['prodopt'].toString();
            this.SetChild('prodopt', new ProductOption(_objid, this.data, this._objoptions), 'prodopt');
        }
        else {
            this.SetChild('prodopt', null, 'prodopt');
        }
    }

    private _ddbb(info): _PrintProductOptionData {
        let _option: _PrintProductOptionData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            product: info['product'] ? parseInt(info['product']): null,
            prodopt: info['prodopt'] ? parseInt(info['prodopt']): null
        };
        return _option;
    }

    protected _OnUpdate(info){
        let _option = this._ddbb(info);
        this.patchValue(this._option, 'objid', _option['objid']);
        this.patchValue(this._option, 'created', _option['created']);
        this.DoPatchValues(_option);
    }   
}

export class PrintProductOption extends PrintProductOptionData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRINTERPRODUCTOPT', objid, data, objoptions);
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []): PrintProductOption {
        return this._Copy(store) as PrintProductOption;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if (this.ToInsert){
                this.product.DelOption(this);
            }
            this.product.ToUpdate = true;
        }                

        super.status = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE') && this.product.IsValid;
    }    
}
