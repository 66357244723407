import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { CatalogObject } from './catalog';
import { ProductCategory } from './productcategory';
import { ProductOption } from './productoption';

export interface _ProductDepend {
    category: number;
    option: number;
};

interface _ProductDependData extends _ProductDepend {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ProductDependData extends CatalogObject {
    protected _depend: _ProductDependData = {
        category: null,
        option: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._depend.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._depend.created;
    }

    get option(): ProductOption {
        return this._children['option'] || null;
    }

    set option(value: ProductOption){
        if (this.SetChild('option', value, 'option')){
            this.ToUpdate = true;
        }
    }

    get category(): ProductCategory {
        return this._children['category'] || null;
    }

    set category(value: ProductCategory){
        if (this.SetChild('category', value, 'category')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            category: this._depend.category,
            productopt: this._depend.option
        };
    }

    protected get Depend() {
        return {
            category: { item: this.category, relation_info: { to: 'depends', by: 'category' } },    // this[by -> 'category'][to -> 'depends'] => this
            productopt: { item: this.option, relation_info: { to: null, by: 'option' } }            // no relation to this in this[by -> 'option']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_depend: _ProductDepend){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._depend, 'category', _depend['category']) || _toUpdate;
        _toUpdate = this.patchValue(this._depend, 'option', _depend['option']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_depend: _ProductDepend){
        if (this._patchData(_depend)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._depend;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_depend: _ProductDepend){
        this._patchData(_depend);

        if (_depend['category']){      // update children: 'category'
            let _objid = _depend['category'].toString();
            this.SetChild('category', new ProductCategory(_objid, this.data, this._objoptions), 'category');
        }
        else {
            this.SetChild('category', null, 'category');
        }
        
        if (_depend['option']){    // update children: 'option'
            let _objid = _depend['option'].toString();
            this.SetChild('option', new ProductOption(_objid, this.data, this._objoptions), 'option');
        }
        else {
            this.SetChild('option', null, 'option');
        }
    }

    private _ddbb(info): _ProductDependData {
        let _depend: _ProductDependData = {
            objid: info['objid'],
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            category: info['category'] ? parseInt(info['category']) : null,
            option: info['productopt'] ? parseInt(info['productopt']) : null
        };
        return _depend;
    }

    protected _OnUpdate(info){
        let _depend = this._ddbb(info);
        this.patchValue(this._depend, 'objid', _depend['objid']);
        this.patchValue(this._depend, 'created', _depend['created']);
        this.DoPatchValues(_depend);
    }
}

export class ProductDepend extends ProductDependData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('CATEGORYDEP', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ProductDepend {
        return this._Copy(store) as ProductDepend;
    }
}
