import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Product } from './product';
import { ProductSelect } from './productselect';
import { Family } from './family';

export interface _FamilyProduct {
    status: string;
    family: number;
    sort: number;
    preselect: number;
    product: number;
};

interface _FamilyProductData extends _FamilyProduct {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class FamilyProductData extends DataObject {
    protected _product: _FamilyProductData = {
        status: null,
        family: null,
        sort: 0,
        preselect: null,
        product: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._product.created = new Date();
    }  
    
    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._product.created;
    }

    get status(): string {
        return this._product.status;
    }

    set status(value: string){
        if (this.patchValue(this._product, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get family() : Family {
        return this._children['family'] || null;
    }

    set family(value: Family) {
        if (this.SetChild('family', value, 'family')){
            this.ToUpdate = true;
        }
    }

    get sort() : number {
        return this._product.sort;
    }

    set sort(value: number) {
        if (this.patchValue(this._product, 'sort', value)){
            this.ToUpdate = true;
        }
    }

    get product() : Product {
        return this._children['product'] || null;
    }

    set product(value: Product) {
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get select(): ProductSelect {
        return this._children['preselect'] || null;
    }

    set select(value: ProductSelect) {
        if (this.SetChild('preselect', value, 'preselect')){
            this.ToUpdate = true;
        }
    }    

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._product.status,
            family: this._product.family,
            sort: this._product.sort,
            preselect: this._product.preselect,
            product: this._product.product
        };
    }

    protected get Depend(){
        return {
            family: { item: this.family, relation_info: { to: 'items', by: 'family' } },        // this[by -> 'family'][to -> 'items'] => this
            preselect: { item: this.select, relation_info: { to: null, by: 'preselect' } },     // no relation to this in this[by -> 'preselect']
            product: { item: this.product, relation_info: { to: null, by: 'product' } }         // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_product: _FamilyProduct){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._product, 'status', _product['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'family', _product['family']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'sort', _product['sort']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'preselect', _product['preselect']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'product', _product['product']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_product: _FamilyProduct){
        if (this._patchData(_product)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._product;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_product: _FamilyProduct){
        this._patchData(_product);

        if (_product['family']){    // update children: 'family'
            let _objid = _product['family'].toString();
            this.SetChild('family', new Family(_objid, this.data, this._objoptions), 'family')
        }
        else {
            this.SetChild('family', null, 'family');
        }

        if (_product['preselect']){    // update children: 'preselect'
            let _objid = _product['preselect'].toString();
            this.SetChild('preselect', new ProductSelect(_objid, this.data, this._objoptions), 'preselect')
        }
        else {
            this.SetChild('preselect', null, 'preselect');
        }

        if (_product['product']){    // update children: 'product'
            let _objid = _product['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product');
        }
    }

    private _ddbb(info): _FamilyProductData {
        let _product: _FamilyProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            family: info['family'] ? parseInt(info['family']) : null,
            sort: info['sort'] ? parseInt(info['sort']) : 0,
            preselect: info['preselect'] ? parseInt(info['preselect']) : null,
            product: info['product'] ? parseInt(info['product']) : null,
        };
        return _product;
    }

    protected _OnUpdate(info){
        let _product = this._ddbb(info);
        this.patchValue(this._product, 'objid', _product['objid']);
        this.patchValue(this._product, 'created', _product['created']);
        this.DoPatchValues(_product);
    }
}

export class FamilyProduct extends FamilyProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('FAMILYPRODUCT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): FamilyProduct {
        return this._Copy(store) as FamilyProduct;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;
        if (this.family){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.family.DelItem(this);
            }
            else {
                this.family.DoRefresh('FAMILYPRODUCT');
            }
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get item() : Product | ProductSelect {
        return this.select || this.product;
    }

    set item(value: Product | ProductSelect){
        if (value instanceof Product){
            this.product = value;
        }

        if (value instanceof ProductSelect){
            this.select = value;
        }
    }

    get IsValid() {
        return this.status && (this.status != 'DE') && this.item.IsValid;
    }
}