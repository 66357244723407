import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { TicketChange } from "./ticketchange";

/*******************************/
/* TICKETSII INSTANCE          */
/*******************************/

export interface _TicketSII {
    change: number,
    region: string,
    sent: Date,
    valid: string
};

interface _TicketSIIData extends _TicketSII {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketSIIData extends DataObject {
    protected _ticketsii: _TicketSIIData = {
        change: null,
        region: null,
        sent: null,
        valid: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);

        this._ticketsii.created = new Date();
        this._ticketsii.updated = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._ticketsii.created;
    }

    get updated(){
        return this._ticketsii.updated;        
    }

    get change(): TicketChange {
        return this._children['change'] || null;
    }

    set change(value: TicketChange){
        if (this.SetChild('change', value, 'change')){
            this.ToUpdate = true;
        }
    }

    /* read only properties */    

    get region(){
        return this._ticketsii.region;
    }

    get sent(){
        return this._ticketsii.sent;
    }

    get valid(){
        return this._ticketsii.valid;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            tckchg: this._ticketsii.change
        };
    }

    protected get Depend() {
        return {
            change: { item: this.change, relation_info: { to: 'ticketsii', by: 'change' } },    // this[by -> 'change'][to -> 'ticketsii'] => this
        };
    }

    protected get Children(){
        return [];
    }


    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_ticketsii: _TicketSII){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._ticketsii, 'change', _ticketsii['change']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketsii, 'region', _ticketsii['region']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketsii, 'sent', _ticketsii['sent']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketsii, 'valid', _ticketsii['valid']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_ticketsii: _TicketSII){
        if (this._patchData(_ticketsii)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._ticketsii;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_ticketsii: _TicketSII){
        this._patchData(_ticketsii);

        if (_ticketsii['change']){    // update children: 'change'
            let _objid = _ticketsii['objid'].toString();
            this.SetChild('change', new TicketChange(_objid, this.data, this._objoptions), 'change')
        }
        else {
            this.SetChild('change', null, 'change');
        }
    }

    private _ddbb(info): _TicketSIIData {
        let _ticketsii: _TicketSIIData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            change: info['tckchg'] ? parseInt(info['tckchg']) : null,
            region: info['region'],
            sent: info['sent'] ? new Date(Date.parse(this.mysqlToDateStr(info['sent']))) : null,
            valid: info['valid'],
        };
        return _ticketsii;
    }

    protected _OnUpdate(info){
        let _ticketsii = this._ddbb(info);
        this.patchValue(this._ticketsii, 'objid', _ticketsii['objid']);
        this.patchValue(this._ticketsii, 'created', _ticketsii['created']);
        this.patchValue(this._ticketsii, 'updated', _ticketsii['updated']);
        this.DoPatchValues(_ticketsii);
    }
}

export class TicketSII extends TicketSIIData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETSII', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketSII {
        return this._Copy(store) as TicketSII;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/
    
    get IsValid(){
        return (this.valid != null);
    }
}

