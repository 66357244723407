import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { NgcCookieConsentService, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { AppConstants } from '@app/app.constants';
import { platformService } from '@app/modules/common/platform';
import { kioskService } from '@app/modules/common/kiosk';
import { httpService } from '@app/modules/common/http';
import { languageService } from '@app/modules/common/language';
import { viewService } from '@app/modules/view';
import { logsService } from '@app/modules/logs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy  {

    private get _CookieConfig() : NgcCookieConsentConfig {
        return {
            cookie: {
                domain: AppConstants.domain
            },
            enabled: true,
            position: "bottom",
            theme: "classic",
            palette: {
                popup: {
                    background: "#000000",
                    text: "#ffffff",
                    link: "#ffffff"
                },
                button: {
                    background: "#f1d600",
                  text: "#000000",
                  border: "transparent"
                }
            },
            type: "info",         
            content: {
                message: this.lang.tr('@cookie_message'),
                dismiss: this.lang.tr('@cookie_dismiss'),
                deny: this.lang.tr('@cookie_deny'),
                link: this.lang.tr('@cookie_link'),
                href: "https://cookiesandyou.com",
                policy: "Cookie Policy"
            }      
        };
    }

    private _addGoogleAnalytics() {
        let _hme_domain = AppConstants.domain;
        let _app_domain = AppConstants.apppath + "." + AppConstants.domain;

        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${AppConstants.GoogleAnalitics}`;
        document.head.appendChild(script1);
    
        const script2 = document.createElement('script');
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
    
          gtag('config', '${AppConstants.GoogleAnalitics}', {
            'cookie_domain': 'auto',
            'allow_linker': true
          });
    
          gtag('set', 'linker', {
            'domains': ['${_hme_domain}', '${_app_domain}']
          });
        `;

        document.head.appendChild(script2);
    }

    constructor(private lang: languageService, private platform: platformService, private logs: logsService, private kiosk: kioskService, private http: httpService, public view: viewService, private swUpdate: SwUpdate, private router: ActivatedRoute, private ccService: NgcCookieConsentService) {
        // nothing to do
    }

    private _cookie_subscription = null;    
    private _lang_subscription = null;
    private _params_subscription = null;
    private _online_subscription = null;

    private _cookie_initialized = false;
    ngOnInit() {
        // only when running on browser
        if (!document.URL.startsWith('http://localhost/')){

            // check for service worker updates
            this.platform.ready().then(
                data => {
                    this.CheckForUpdates();
            })

            // request cookies consent
            this._cookie_subscription = this.ccService.initialized$.subscribe(
            (event) => {
                this._cookie_initialized = true;
            });
    
            this.ccService.init(this._CookieConfig);
    
            this._lang_subscription = this.lang.OnSetLanguage.subscribe(
            data => {
                if (this._cookie_initialized){
                    this.ccService.destroy();
                }
                this.ccService.init(this._CookieConfig);  
            });        
        }

        this._params_subscription = this.router.queryParams.subscribe(
        params => {
            if ('lang' in params){
              this.lang.SetLanguage(params['lang']);
            }
        });

        this._addGoogleAnalytics();
   }   
    
    ngOnDestroy(){
        if (this._params_subscription){
            this._params_subscription.unsubscribe();
            this._params_subscription = null;
        }

        if (this._cookie_subscription){
            this._cookie_subscription.unsubscribe();
            this._cookie_subscription = null;
        }
                
        if (this._lang_subscription){
            this._lang_subscription.unsubscribe();
            this._lang_subscription = null;
        }      

        if (this._online_subscription){
            this._online_subscription.unsubscribe();
            this._online_subscription = null;
        }      
    }

    private _update_interval = null;
    private CheckForUpdates(){
        if (this.swUpdate.isEnabled) {
            console.info("[UNPISPAS] Checking for SW updates..");
            this.swUpdate.checkForUpdate().then((available) => {
                this.view.SwUpdate = available;
            });
    
            // check for updates if back from offline period
            this._online_subscription = this.http.OnOnline.subscribe(
            online => {
                if (online){
                    if (!this.view.SwUpdate){
                        console.info("[UNPISPAS] Checking for SW updates..");
                        this.swUpdate.checkForUpdate().then((available) => {
                            this.view.SwUpdate = available;
                        });
                    }                    
                }
            });

            // check for updates every 4 hours
            this._update_interval = setInterval(() => {
                if (!this.view.SwUpdate){
                    console.info("[UNPISPAS] Checking for SW updates..");
                    this.swUpdate.checkForUpdate().then((available) => {
                        this.view.SwUpdate = available;
                    });
                }
                else {
                    clearInterval(this._update_interval);
                }
            }, 1000 * 3600 * 4);    // every 4 hours
        }
    }
}
