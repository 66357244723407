import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

export interface _Address {
    formatted: string;
    door: string;
    latitude: number;
    longitude: number;
    accuracy: number;
    timezone: string;
};

interface _AddressData extends _Address {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class AddressData extends DataObject {
    protected _address: _AddressData = {
        formatted: null,
        door: null,
        latitude: null,
        longitude: null,
        accuracy: null,
        timezone: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._address.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._address.created;
    }

    get formatted(): string {
        return this._address.formatted;
    }

    set formatted(value: string){
        if (this.patchValue(this._address, 'formatted', value)){
            this.ToUpdate = true;
        }
    }

    get door(): string{
        return this._address.door;
    }

    set door(value: string){
        if (this.patchValue(this._address, 'door', value)){
            this.ToUpdate = true;
        }
    }

    get latitude(): number {
        return this._address.latitude;
    }

    set latitude(value: number) {
        if (this.patchValue(this._address, 'latitude', value)){
            this.ToUpdate = true;
        }
    }

    get longitude(): number {
        return this._address.longitude;
    }

    set longitude(value: number) {
        if (this.patchValue(this._address, 'longitude', value)){
            this.ToUpdate = true;
        }
    }

    get accuracy(): number {
        return this._address.accuracy;
    }

    set accuracy(value: number) {
        if (this.patchValue(this._address, 'accuracy', value)){
            this.ToUpdate = true;
        }
    }

    get timezone(): string {
        return this._address.timezone;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            formatted: this._address.formatted,
            door: this._address.door,
            latitude: this._address.latitude,
            longitude: this._address.longitude,
            accuracy: this._address.accuracy,
            timezone: this._address.timezone
        };
    }

    protected get Depend(){
        return { /* empty */ };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_address: _Address){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._address, 'formatted', _address['formatted']) || _toUpdate;
        _toUpdate = this.patchValue(this._address, 'door', _address['door']) || _toUpdate;
        _toUpdate = this.patchValue(this._address, 'latitude', _address['latitude']) || _toUpdate;
        _toUpdate = this.patchValue(this._address, 'longitude', _address['longitude']) || _toUpdate;
        _toUpdate = this.patchValue(this._address, 'accuracy', _address['accuracy']) || _toUpdate;
        _toUpdate = this.patchValue(this._address, 'timezone', _address['timezone']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_address: _Address){
        if (this._patchData(_address)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._address;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_address: _Address){
        this._patchData(_address);
    }

    private _ddbb(info): _AddressData {
        let _address: _AddressData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            formatted: info['formatted'],
            door: info['door'],
            latitude: info['latitude'] ? parseFloat(info['latitude']) : 0,
            longitude: info['longitude'] ? parseFloat(info['longitude']): 0,
            accuracy: info['accuracy'] ? parseInt(info['accuracy']): 0,
            timezone: info['timezone'] ? info['timezone'] : 'UTC'
        };
        return _address;
    }

    protected _OnUpdate(info){
        let _address = this._ddbb(info);
        this.patchValue(this._address, 'objid', _address['objid']);
        this.patchValue(this._address, 'created', _address['created']);
        this.DoPatchValues(_address);   
    }
}

export class Address extends AddressData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('ADDRESS', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : Address {
        return this._Copy(store) as Address;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        return this.formatted && (this.formatted != '') && ((this.latitude != 0) || (this.longitude != 0));
    }

}
