import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Audit } from './audit';
import { AccountInvoice } from './accountinvoice';
import { Ticket } from './ticket';

export interface _AuditInfo {
    audit: number;
    cash: number;
    card: number;
    webpay: number;
    tickets: {
        completed_amount: number;
        cancelled_amount: number;
    };
    items: {
        completed_amount: number;
        completed_income: number;
        cancelled_amount: number;
        cancelled_income: number;
        othehouse_amount: number;
        othehouse_income: number;
    },
    ticketsii: {
        ok: number,
        ko: number
    },
    ticketbai: {
        ok: number,
        ko: number
    }
};

interface _AuditInfoData extends _AuditInfo {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class AuditInfoData extends DataObject {
    protected _audit: _AuditInfoData = {
        audit: null,
        cash: null,
        card: null,
        webpay: null,
        tickets: {
            completed_amount: null,
            cancelled_amount: null
        },
        items: {
            completed_amount: null,
            completed_income: null,
            cancelled_amount: null,
            cancelled_income: null,
            othehouse_amount: null,
            othehouse_income: null
        },
        ticketsii: {
            ok: null,
            ko: null
        },
        ticketbai: {
            ok: null,
            ko: null
        }        
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._audit.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._audit.created;
    }

    get audit(): Audit{
        return this._children['audit'] || null;
    }

    set audit(value: Audit){
        if (this.SetChild('audit', value, 'audit')){
            this.ToUpdate = true;
        }
    }

    get cash(): number{
        return this._audit.cash;
    }

    get card(): number{
        return this._audit.card;
    }

    get webpay(): number{
        return this._audit.webpay;
    }

    get tickets(): any {
        return this._audit.tickets;
    }
 
    get items(): any {
        return this._audit.items;
    }

    get ticketsii(): any {
        return this._audit.ticketsii;
    }

    get ticketbai(): any {
        return this._audit.ticketbai;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change(){
        return {
            audit: this._audit.audit    // data is updated on server side (on every ticket commit)
        }
    }

    protected get Depend() {
        return {
            audit: { item: this.audit, relation_info: { to: 'auditinfo', by: 'audit' } }    // this[by -> 'audit'][to -> 'auditinfo'] => this
        };
    }

    protected get Children() {
        return [ /* empty */ ];
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_audit: _AuditInfo){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._audit, 'audit', _audit['audit']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'cash', _audit['cash']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'card', _audit['card']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'webpay', _audit['webpay']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'tickets', _audit['tickets']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'items', _audit['items']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'ticketsii', _audit['ticketsii']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'ticketbai', _audit['ticketbai']) || _toUpdate;

        return _toUpdate;
    }
    
    set Data(_audit: _AuditInfo){
        if (this._patchData(_audit)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._audit;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _AuditInfoData {
        let _audit: _AuditInfoData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            audit: info['audit'] ? parseInt(info['audit']) : null,
            cash: info['cash'] ? parseFloat(info['cash']) : null,
            card: info['card'] ? parseFloat(info['card']) : null,
            webpay: info['webpay'] ? parseFloat(info['webpay']) : null,
            tickets: {
                completed_amount: info['tickets_completed_amount'] ? parseInt(info['tickets_completed_amount']) : null,
                cancelled_amount: info['tickets_cancelled_amount'] ? parseInt(info['tickets_cancelled_amount']) : null,
            },
            items: {
                completed_amount: info['items_completed_amount'] ? parseInt(info['items_completed_amount']) : null,
                completed_income: info['items_completed_income'] ? parseFloat(info['items_completed_income']) : null,
                cancelled_amount: info['items_cancelled_amount'] ? parseInt(info['items_cancelled_amount']) : null,
                cancelled_income: info['items_cancelled_income'] ? parseFloat(info['items_cancelled_income']) : null,
                othehouse_amount: info['items_onthehouse_amount'] ? parseInt(info['items_onthehouse_amount']) : null,
                othehouse_income: info['items_onthehouse_income'] ? parseFloat(info['items_onthehouse_income']) : null,
            },
            ticketsii: {
                ok: info['ticketsii_ok'] ? parseInt(info['ticketsii_ok']) : null,
                ko: info['ticketsii_ko'] ? parseInt(info['ticketsii_ko']) : null,
            },
            ticketbai: {
                ok: info['ticketbai_ok'] ? parseInt(info['ticketbai_ok']) : null,
                ko: info['ticketbai_ko'] ? parseInt(info['ticketbai_ko']) : null,
            }
        };
        return _audit;
    }

    private DoPatchValues(_audit: _AuditInfo){
        this._patchData(_audit);

        if (_audit['audit']){     // update children: 'audit'
            let _objid = _audit['audit'].toString();
            this.SetChild('audit', new Audit(_objid, this.data, this._objoptions), 'audit');
        }  
        else {
            this.SetChild('audit', null, 'audit');
        }
    }

    protected _OnUpdate(info){
        let _audit = this._ddbb(info);
        this.patchValue(this._audit, 'objid', _audit['objid']);
        this.patchValue(this._audit, 'created', _audit['created']);
        this.DoPatchValues(_audit);
    }
}

export class AuditInfo extends AuditInfoData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('AUDITINFO', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): AuditInfo {
        return this._Copy(store) as AuditInfo;
    }

    /****************************/
    /* BASE OVERLOAD           */
    /****************************/

    protected get _CanUpdate(){
        return true;    // overwrite local changes with server changes
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    private _sii_sending = false;
    private _bai_sending = false;
    
    get SIISending(): boolean{
        return this._sii_sending;
    }

    set SIISending(value: boolean){
        this._sii_sending = value;
        this.DoRefresh('AUDITINFO');
    }

    OnSIIResponse(_ok, _ko){
        this._audit.ticketsii = {
            ok: _ok,
            ko: _ko
        };
    }

    get BAISending(): boolean{
        return this._bai_sending;
    }

    set BAISending(value: boolean){
        this._bai_sending = value;
        this.DoRefresh('AUDITINFO');
    }

    OnBAIResponse(_ok, _ko){
        this._audit.ticketbai = {
            ok: _ok,
            ko: _ko
        };
    }

    private _AuditTickets() : Array<Ticket> {
        let _audittickets = [];

        let _prev = 0;
        let _next = this.audit.created.getTime();

        let _audits = this.audit.place.audits.slice(0);
        _audits = _audits.sort((a, b) => {
            return a.created.getTime() - b.created.getTime();
        });

        if (_audits.length > 0){
            let _idx = _audits.indexOf(this.audit);
            if (_idx < 0){    // not found (get the last one)
                _prev = _audits[_audits.length-1].created.getTime();
            }
    
            if (_idx > 0){    // found (get the previous one)
                _prev = _audits[_idx-1].created.getTime();
            }    
        }

        let _tickets = this.audit.place.tickets;
        for(let _ticket of _tickets){
            if ((!_ticket.paidon) && (!['CC', 'DE'].includes(_ticket.status))){
                continue;   // not paid and not cancelled: the ticket is still open
            }

            let _date = _ticket.paidon?.getTime() || _ticket.updated.getTime();
            if ((_date >= _prev) && (_date < _next)){
                _audittickets.push(_ticket);
            }
        }

        return _audittickets;
    }

    Reload(){
        if (!this._audit){
            return;
        }

        let _audit: _AuditInfoData = {
            audit: null,
            cash: 0,
            card: 0,
            webpay: 0,
            tickets: {
                completed_amount: 0,
                cancelled_amount: 0
            },
            items: {
                completed_amount: 0,
                completed_income: 0,
                cancelled_amount: 0,
                cancelled_income: 0,
                othehouse_amount: 0,
                othehouse_income: 0
            },
            ticketsii: {
                ok: 0,
                ko: 0
            },
            ticketbai: {
                ok: 0,
                ko: 0
            }
        };

        let _invveac = new Set <AccountInvoice> ();

        // calculate the audit information
        let _tickets = this._AuditTickets();
        for(let _ticket of _tickets){
            let _iscancelled = (['CC', 'DE'].indexOf(_ticket.status)  != -1);
            if (_iscancelled){
                _audit.tickets.cancelled_amount += 1;
            }
            else {
                _audit.tickets.completed_amount += 1;

                switch(_ticket.payment){
                    case 'PAYCASH':
                        _audit.cash += _ticket.price;
                        break;
                    case 'PAYCARD':
                        _audit.card += _ticket.price;
                        break;
                    case 'PAYLINE':
                        _audit.webpay += _ticket.price;
                        break;
                }                
            }

            for(let _product of _ticket.products){
                if (_iscancelled || (['DE', 'CC'].includes(_product.status))) {
                    _audit.items.cancelled_amount += 1;
                    _audit.items.cancelled_income += _product.price;
                }
                else {
                    _audit.items.completed_amount += 1;
                    _audit.items.completed_income += _product.charge;

                    if (_product.fixed){
                        _audit.items.othehouse_amount += 1;
                        _audit.items.othehouse_income += (_product.price - _product.charge);
                    }
                }
            }

            // add counters for the tickets (if not paid to account)
            if (_ticket.invceac == null){
                if (_ticket.ticketsii?.IsValid){
                    _audit.ticketsii.ok += 1;
                }
                else {
                    _audit.ticketsii.ko += 1;
                }    

                if (_ticket.ticketbai?.IsValid){
                    _audit.ticketbai.ok += 1;
                }
                else {
                    _audit.ticketbai.ko += 1;
                }
            }

            // add counters for the invoice accounts
            else {  
                if (!_invveac.has(_ticket.invceac)){
                    if (_ticket.invceac.ticketsii?.IsValid){
                        _audit.ticketsii.ok += 1;
                    }
                    else {
                        _audit.ticketsii.ko += 1;
                    }

                    if (_ticket.invceac.ticketbai?.IsValid){
                        _audit.ticketbai.ok += 1;
                    }
                    else {
                        _audit.ticketbai.ko += 1;
                    }
                }

                _invveac.add(_ticket.invceac);  // include this account invoice (already counted)
            }
        }

        this.Data = _audit;
    }
}

