import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import  { ScaleDevice } from '@app/modules/model/scale';

import { AppConstants } from '@app/app.constants';
import { GenericUtils } from '@app/app.commonutils';

import { httpService } from '@app/modules/common/http';
import { syncService } from '@app/modules/sync';

/*****************************/
/* CONNECTION ABSTRACT CLASS */
/*****************************/

export abstract class WSConnection {
    protected _service: _Service = null; 
    get service() : _Service{
        return this._service;
    }

    set service(value: _Service){
        this._service = value;;
    }
    
    protected _version: string = null;       
    get version(){
        return this._version;
    }

    set version(value){
        this._version = value;
    }
    
    protected _svcname: string = null;
    get svcname(){
        return this._svcname;
    }

    private _contype: 'WSL' | 'WSR' = null;
    get contype(){
        return this._contype;
    }

    protected _onDisconnected = new Subject<any> ();  
    public OnDisconnected = this._onDisconnected.asObservable(); 

    protected _onMessageSent = new Subject<any> ();  
    public OnMessageSent = this._onMessageSent.asObservable();  

    protected _onMessageRecv = new Subject<any> ();    
    public OnMessageRecv = this._onMessageRecv.asObservable();  

    constructor(type, name){
        this._svcname = name;
        this._contype = type;
    }    

    Disconnect(){
        this._onDisconnected.next();
    }

    abstract Send(module, message);
}

/*****************************/
/* INPUT ABSTRACT CLASS      */
/*****************************/

export abstract class WSInput {
    private _connection = null; 
    private _target = null;
    private _inputid = null;

    private _read_subscription = null;
    private _conn_subscription = null;

    protected _onInputRead = new Subject<string> ();    
    public OnInputRead = this._onInputRead.asObservable();  
    protected _onInputLost = new Subject<string> ();    
    public OnInputLost = this._onInputLost.asObservable();  


    constructor(connection, target){
        if (connection.contype != 'WSL'){
            console.error("[" + connection.contype + "] cannot read from remote websocket connection")
        }
        else {
            this._connection = connection;
            this._conn_subscription = connection.OnDisconnected.subscribe(
            data => {
                this._onInputLost.next();
            });

            this._target = target;    
        }
    }  

    abstract doIniRead();
    abstract doEndRead();

    async OnDestroy(){
        await this.doEndRead();

        if (this._read_subscription){
            this._read_subscription.unsubscribe();
            this._read_subscription = null;
        }

        if (this._conn_subscription){
            this._conn_subscription.unsubscribe();
            this._conn_subscription = null;
        }  
        
        if (this._connection){
            this._connection.Disconnect();
            this._connection = null;
        }
    }

    /***************************/
    /* PROTECTED METHODS       */
    /***************************/

    protected async _doIniRead(_action){
        if (!this._connection){
            return null;    // not connected
        }

        let _response = await this._connection.Send(this._target, _action);
        if (_response && _response.exec == 'SUCCESS'){
            this._inputid = _response.data;

            console.info("[" + this._connection.contype + "] starting read on target: '" + this._target + "' with session (" + this._inputid + ")");
            if (this._read_subscription){
                this._read_subscription.unsubscribe();
                this._read_subscription = null;
            }

            this._read_subscription = this._connection.OnMessageRecv.subscribe(
            data => {
                if (data['uuid'] == this._inputid){
                    this._onInputRead.next(data['data']);    
                }
            });


            return _response;
        }
        else {
            console.error("[" + this._connection.contype + "] read request to target '" + this._target + "' failed! (" + (_response ? _response.data : 'no response') + ")")
            return null;
        }
    }

    protected async _doEndRead(_action){
        if (this._read_subscription){
            this._read_subscription.unsubscribe();
            this._read_subscription = null;
        }

        let _response = await this._connection.Send(this._target, _action);
        if (_response && _response.exec == 'SUCCESS'){
            console.info("[" + this._connection.contype + "] stop request to target: '" + this._target + "'");
        }
        else {
            console.error("[" + this._connection.contype + "] stop request to target '" + this._target + "' failed! (" + (_response ? _response.data : 'no response') + ")")
        }
    }
}

class InputScale extends WSInput {
    constructor(connection: WSConnection, private device: ScaleDevice){
        super(connection, 'SCALE');
    }

    async doIniRead(){
        if (!this.device){
            return null;    // no device provided 
        }

        return this._doIniRead({
            command: 'START',
            device: this.device.model,
            port: this.device.port || 'none'
        });
    }

    async doEndRead(){
        if (!this.device){
            return null;    // no device provided 
        }

        return this._doEndRead({
            command: 'STOP',
            device: this.device.model,
            port: this.device.port || 'none'
        });
    }
}

/*****************************/
/* SERVICE ABSTRACT CLASS    */
/*****************************/

abstract class _Service {
    protected host = null;
    protected port = null;
    
    abstract Connect(host, port, name) : Promise <WSConnection>;
} 

/*****************************/
/* LOCAL WS CONNECTION       */
/*****************************/

/*
    The connection is performed from the browser to the application (websocket server) running in localhost
    The connection is capable od sending and receiving messages to and from this server
*/

class WSConnectionL extends WSConnection {
    private ws: WebSocket = null;

    constructor(name, _ws){
        super('WSL', name);    // this is temporal: will be overwritten by the ws server
        
        this.ws = _ws;
        _ws.onmessage = (event) => {
            let _message_json = JSON.parse(event.data)
            if (_message_json){
                this._onMessageRecv.next({
                    name: _message_json['SERVICE_NAME'],    // service identifier configured in the ws 
                    vers: _message_json['SERVICE_VERS'],    // service version configured in the ws
                    uuid: _message_json['MESSAGE_UUID'],    // message identifier (in case of response)
                    exec: _message_json['MESSAGE_EXEC'],    // execution result
                    data: _message_json['MESSAGE_DATA']     // message content (result or error message)
                });  
            } 
            else {
                this._onMessageRecv.next({
                    exec: 'SUCCESS',                        // execution result
                    data: event.data                        // response is not a JSON object
                });  
            }
        };

        _ws.onclose = () => {
            if (this.ws){   // null if we have closed the session
                console.warn("[" + this.contype + "] Websocket connection lost");
                
                this._stopKeepAlive();
                this._onDisconnected.next();    
            }
        };

        this._startKeepAlive();     // keep-alive ping every 20 seconds
    }

    Disconnect(){
        if (this.ws){
            this.ws.close();
            this.ws = null;

            console.info("[" + this.contype + "] Websocket connection closed");
        }

        this._stopKeepAlive();
        super.Disconnect();
    }
    
    Send(module, message){
        return new Promise <any> ((resolve) => {
            if (this.ws){
                let _uuidv4 = GenericUtils.uuidv4();
                let _sendmsg = {
                    UUIDV4: _uuidv4, 
                    TARGET: module, 
                    ACTION: message
                };

                let _subscription = this.OnMessageRecv.subscribe(
                _response => {
                    if (_response && (_response['uuid'] == _uuidv4)){
                        _subscription.unsubscribe();

                        if (_response.name != this.svcname){
                            console.warn("[" + this.contype + "] Local server configured for '" + _response.name + "' ('" + this.svcname + "' is expected)")
                        }

                        this._version = _response.vers; 
                        this._svcname = _response.name;

                        resolve({
                            exec: _response['exec'],
                            data: _response['data'] 
                        });
                    }
                });

                if (this.ws.readyState === WebSocket.OPEN){
                    this.ws.send(JSON.stringify(_sendmsg));
                    this._onMessageSent.next(_sendmsg);                        
                }
                else {
                    resolve(null);  // send error
                }
            }
            else {
                resolve(null);     // not connected
            }
        })
    }

    private _keepalive_interval = null;
    private _startKeepAlive() {
        this._keepalive_interval = setInterval(() => {
            let _timeout = setTimeout(() => {
                console.warn("[" + this.contype + "] ping request not satisfied after 5 seconds");
                this.Disconnect();
            }, 5000);

            this.Send("BASE", "PING").then(
            _response => {
                clearTimeout(_timeout);
            })
        }, 20000);
    }

    private _stopKeepAlive() {
        if (this._keepalive_interval) {
            clearInterval(this._keepalive_interval);
            this._keepalive_interval = null;
        }
    }    
};

class LocalService extends _Service {
    Connect(host, port, name) {
        this.host = host;
        this.port = port;

        let _url = "ws://" + this.host + ":" + this.port;
        return new Promise <WSConnection> ((resolve) => {
            if (!this.host || !this.port){
                console.info("[WSL] Websocket connection error (not provided)");
                return resolve(null);
            }
    
            let _ws = null;
            try {
                _ws = new WebSocket(_url);
            }
            catch(error){
                return resolve(null);     // connection failed
            }

            _ws.onerror = () => {
                console.warn("[WSL] Websocket connection error");
                resolve(null);          // connection failed (could open connection)
            };                   

            _ws.onopen = () => {
                let _connection = new WSConnectionL(name, _ws);
                if (_connection){
                    _connection.service = this;

                    let _subscription = _connection.OnDisconnected.subscribe(
                    data => {
                        _subscription.unsubscribe();
                        resolve(null);      // connection closed
                    });        

                    _connection.Send("BASE", "CHECK").then(
                    _response => {
                        if (_response && _response.exec == 'SUCCESS'){
                            console.info("[WSL] Websocket connection ready");
                            resolve(_connection);   // connected          
                        }
                        else {
                            resolve(null);      // connection failed (invalid check)
                        }
                    });
                }
            };
        });
    }
}

/*****************************/
/* REMOTE WS CONNECTION      */
/*****************************/

/*
    The connection is performed from the wsr REST server to the wss (websocket server) running hosted
    The local websocket server has registered to the remote wss server that will act as gateway
    Messages sent to the wsr REST server will be sent to the wss server and then back to the targeted web socket server
    Through this method, the connection is capable od sending single messages to the local running server 
*/

class WSConnectionR extends WSConnection {
    constructor(name, private http: httpService){
        super('WSR', name);
    }

    Disconnect(){
        super.Disconnect();
    }
    
    Send(module, message){
        return new Promise <any> (async (resolve) => {
            let _url = AppConstants.restURL + "send?service=" + this.svcname;

            if (!this.http.IsOnline){
                return resolve(null);
            }
    
            let response = await fetch(_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ TARGET: module, ACTION: message })
            });
            
            if (response.ok) {
                let _response = await response.json();
                resolve({
                    exec: _response['exec'],
                    data: _response['data'] 
                });
            }
            else {
                resolve(null);      // error on execution
            }    
        });
    }
};

class RemoteService extends _Service {

    constructor(private http: httpService){
        super();
    }

    Connect(host, port, name){
        this.host = host;
        this.port = port;

        return new Promise <WSConnection> ((resolve) => {
            let _connection = new WSConnectionR(name, this.http);
            if (_connection){
                _connection.service = this;

                _connection.Send("BASE", "CHECK").then(
                _response => {
                    if (_response && _response.exec == 'SUCCESS'){
                        console.info("[WSR] Websocket connection ready");
                        resolve(_connection);   // connected          
                    }
                    else {
                        resolve(null);      // connection failed (invalid check)
                    }
                });            
            }
        });
    }     
}

/*****************************/
/* DEVICES SERVICE           */
/*****************************/

@Injectable()
export class deviceService {

    private _localservice = null;
    get localService(){
        if (!this._localservice){
            this._localservice = new LocalService()
        }
        return this._localservice;
    }

    private _rmoteservice = null;
    get rmoteService(){
        if (!this._rmoteservice){
            this._rmoteservice = new RemoteService(this.http)
        }
        return this._rmoteservice;
    }

    /****************************/
    /* STARTS HERE              */
    /****************************/

    constructor(private http: httpService, private sync: syncService){
        // nothing to do
    }    

    /****************************/
    /* NAME AVAILABILITY        */
    /****************************/

    private _freeServiceUrl = 'sockets/available.php';

    Available(placeid, service){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._freeServiceUrl, { place: placeid, service: service })
            .then(data => {
                resolve(data);
            }, error => {
                if (error.status != 0){
                    console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._freeServiceUrl + "'");
                }

                resolve(false);
            }); 
        });
    }

    /****************************/
    /* CONNECT CONTROL          */
    /****************************/
        
    Connect(host, port, service, tolocal): Promise <WSConnection> {
        if (tolocal){
            return this.localService.Connect(host, port, service);
        }
        else {
            return this.rmoteService.Connect(host, port, service);
        }
    }

    Disconnect(_connection){
        if (_connection){
            _connection.Disconnect();
        }
    }

    /****************************/
    /* PRINTER ACTIONS          */
    /****************************/

    async doOpen(connection: WSConnection, device){
        if (!connection || !connection.service){
            return;
        }

        let _message = {};
        
        _message[device] = {
            print: null,
            copies: 0,
            open: true
        }
        
        let _response = await connection.Send("PRINT", JSON.stringify(_message));
        if (_response && _response.exec == 'SUCCESS'){
            console.info("[" + connection.contype + "] open request sent to device '" + device + "'")
        }
        else {
            console.error("[" + connection.contype + "] open request to device '" + device + "' failed! (" + (_response ? _response.data : 'no response') + ")")
        }

        return _response;
    }

    async doPrint(connection, device, html, copies = 1){
        if (!connection || !connection.service){
            return;
        }

        let _message = {};

        _message[device] = {
            print: GenericUtils.stringToBase64(html),
            copies: copies,
            open: false
        }

        let _response = await connection.Send("PRINT", JSON.stringify(_message));
        if (_response && _response.exec == 'SUCCESS'){
            console.info("[" + connection.contype + "] print request sent to device '" + device + "'")
        }
        else {
            console.error("[" + connection.contype + "] open request to device '" + device + "' failed! (" + (_response ? _response.data : 'no response') + ")")
        }

        return _response;    
    }    

    /****************************/
    /* INPUT DEVICES            */
    /****************************/

    async WSInputScale(host, port, name, device: ScaleDevice){
        let _connection = await this.Connect(host, port, name, true);
        if (_connection){
            return new InputScale(_connection, device);
        }

        return null;    // not connected
    }
}

