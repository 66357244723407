import { dataService } from '@app/modules/data';

import { DataObject, ObjectOptions } from './base';
import { PayAccount } from './payaccount';
import { Ticket } from './ticket';
import { TicketBAI } from './ticketbai';
import { TicketSII } from './ticketsii';

export interface _AccountInvoice {
    account: number,
    since: Date,
    until: Date,
    series: string;
    invoice: string,
    amount: number,
    payment: string

    sentto?: string;
};

interface _AccountInvoiceData extends _AccountInvoice {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class AccountInvoiceData extends DataObject {
    protected _invoice: _AccountInvoiceData = {
        account: null,
        since: null,
        until: null,
        series: null,
        invoice: null,
        amount: null,
        payment: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);

        this._invoice.created = new Date();
        this._invoice.updated = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._invoice.created;
    }

    get updated(){
        return this._invoice.updated;
    }

    get account(): PayAccount {
        return this._children['account'] || null;
    }

    set account(value: PayAccount){
        if (this.SetChild('account', value, 'account')){
            this.ToUpdate = true;
        }
    }

    get since(): Date {
        return this._invoice.since;
    }

    set since(value: Date){
        if (this.patchValue(this._invoice, 'since', value)){
            this.ToUpdate = true;
        }
    }

    get until(): Date {
        return this._invoice.until;
    }

    set until(value: Date){
        if (this.patchValue(this._invoice, 'until', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._invoice.amount;
    }

    set amount(value: number){
        if (this.patchValue(this._invoice, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    get payment(): string {
        return this._invoice.payment;
    }

    set payment(value: string) {
        if (this.patchValue(this._invoice, 'payment', value)){
            this.ToUpdate = true;
        }
    }

    /* read only properties */

    get series(): string {
        return this._invoice.series;
    }

    get invoice(): string {
        return this._invoice.invoice;
    }

    get sentto(): Array<string> {
        let _sentto = [];

        if (this._invoice.sentto){
            let _srvlst = [ 'TICEKTSII', 'TICKETBAI' ];

            let _server = Array.from(this._invoice.sentto);
            for(let i=0; i < _server.length; i++){
                if (_server[i] == '1'){
                    _sentto.push(_srvlst[i]);
                }
            }    
        }

        return _sentto;
    }

    get ticketbai(): TicketBAI {
        let _ticketbai = this._chldlist['ticketbai'] || [];
        if (_ticketbai.length > 0){
            return _ticketbai[0];
        }
        return null;
    }

    get ticketsii(): TicketSII {
        let _ticketsii = this._chldlist['ticketsii'] || [];
        if (_ticketsii.length > 0){
            return _ticketsii[0];
        }
        return null;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            account: this._invoice.account,
            since: this._invoice.since ? this.dateStrToMysql(this._invoice.since) : null,
            until: this._invoice.until ? this.dateStrToMysql(this._invoice.until) : null,
            series: this._invoice.series,
            invoice: this._invoice.invoice,
            amount: this._invoice.amount,
            payment: this._invoice.payment
        };
    }

    protected get Depend() {
        return {
            account: { item: this.account, relation_info: { to: 'invoices', by: 'account' } }   // this[by -> 'account'][to -> 'invoices'] => this
        };
    }

    protected get Children(){
        let _children = [];

        if (this.ticketbai){
            _children.push(this.ticketbai);
        }

        if (this.ticketsii){
            _children.push(this.ticketsii);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_invoice: _AccountInvoice){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._invoice, 'account', _invoice['account']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'since', _invoice['since']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'until', _invoice['until']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'series', _invoice['series']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'invoice', _invoice['invoice']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'amount', _invoice['amount']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'sentto', _invoice['sentto']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'payment', _invoice['payment']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_invoice: _AccountInvoice){
        if (this._patchData(_invoice)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._invoice;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_invoice: _AccountInvoice){
        this._patchData(_invoice);

        if (_invoice['account']){    // update children: 'account'
            let _objid = _invoice['account'].toString();
            this.SetChild('account', new PayAccount(_objid, this.data, this._objoptions), 'account')
        }
        else {
            this.SetChild('account', null, 'account');
        }
    }

    private _ddbb(info): _AccountInvoiceData {
        let _invoice: _AccountInvoiceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            account: info['account'] ? parseInt(info['account']) : null,
            since: new Date(Date.parse(this.mysqlToDateStr(info['since']))),
            until: new Date(Date.parse(this.mysqlToDateStr(info['until']))),
            series: info['series'],
            invoice: info['invoice'],
            amount: info['amount'] ? parseFloat(info['amount']) : null,
            payment: info['payment'],

            sentto: ('sentto' in info) ? info['sentto'] : null,
        };

        return _invoice;
    }

    protected _OnUpdate(info){
        let _invoice = this._ddbb(info);
        this.patchValue(this._invoice, 'objid', _invoice['objid']);
        this.patchValue(this._invoice, 'created', _invoice['created']);
        this.patchValue(this._invoice, 'updated', _invoice['updated']);
        this.DoPatchValues(_invoice);

        if (info['ticketbai']){   // update children: 'ticketbai'
            this.SetChildren <TicketBAI> (info['ticketbai'], 'ticketbai', TicketBAI, 'ticketbai');
        }

        if (info['ticketsii']){   // update children: 'ticketsii'
            this.SetChildren <TicketSII> (info['ticketsii'], 'ticketsii', TicketSII, 'ticketsii');
        }
    }
}

export class AccountInvoice extends AccountInvoiceData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('ACCOUNTINVOICE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): AccountInvoice {
        return this._Copy(store) as AccountInvoice;
    }  

   /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddTicket(child: Ticket){
        this.AddChild('tickets', child, 'place');
    }

    DelTicket(child: Ticket){
        this.DelChild('tickets', child, 'place');
    }

    async AddTicketBAI(){
        if (this.tickets.length == 0){
            console.error("[INVOICE] This account invoice is empty!");
            return;
        }

        if (this.place.SendBAIEnabled){
            if (this.ticketbai){
                console.warn("[INVOICE] overwriting ticketbai instance will bave no effect");
            }
    
            let _ticketbai = new TicketBAI(null, this.data);
            if (_ticketbai){
                _ticketbai.invceac = this;
            }
    
            let _tbai = await _ticketbai.Refresh();
            if (_tbai != null){
                this.AddChild('ticketbai', _ticketbai, 'invceac')    
            }

            return _tbai;
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get place(){
        return this.account?.place;
    }

    get payment(): string {
        let _payment = super.payment;
        
        switch(_payment){   // backwards compatibility
            case 'CASHPAY':
                return 'PAYCASH';
            case 'CARDPAY':
                return 'PAYCARD';
        }

        if (_payment == null){
            return 'UNKNOWN';
        }

        return _payment; 
    }

    set payment(value: string){
        super.payment = value;
    }

    /*
        Account invoice tickets are added only on creation
        The ticketlist is required for ticketbai xml generation
    */

    get tickets(){
        return this._chldlist['tickets'] || [];
    }

    private _strPrice(price){
        let _price = {
            Str: "0,00",
            Int: "0",
            Dec: "00",
            Val: 0.0,
            Neg: false
        };

        if (price !== null){
            let _parts = Math.abs(price).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _int + ',' + _dec;
            let _val = price;
            let _neg = (price < 0); 

            _price = {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Val: _val,
                Neg: _neg
            }    
        }
        return _price;
    }

    private _splitprice = null;
    private get SplitPrice(){
        if (this._splitprice == null){
            let _rate = Number(this.place.TaxRate);
        
            let _total = this.amount;
            let _base = (Number(_total) / ((100 + _rate)/100));
            let _tax = Number(_total) - Number(_base);
    
            this._splitprice = {
                base: this._strPrice(Math.round(_base * 100) / 100),
                tax: this._strPrice(Math.round(_tax * 100) / 100),
                rate: _rate,
                total: this._strPrice(Math.round(_total * 100) / 100)
            }    
        }

        return this._splitprice;
    }

    get total(): number {
        return this.SplitPrice.total.Val;
    }

    get base(): number {
        return this.SplitPrice.base.Val;
    }

    get rate(): number {
        return this.SplitPrice.rate;        
    }

    get taxes(): number {
        return this.SplitPrice.tax.Val;                
    }

    get sentto(): Array<string> {
        if (this._invoice.sentto){
            return super.sentto;
        }
        else {
            let _sentto = [];
            
            if (this.ticketbai?.IsValid){
                _sentto.push('TICKETBAI');
            }

            if (this.ticketsii?.IsValid){
                _sentto.push('TICKETSII');
            }

            return _sentto;
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    async SetInvoiceNumber(){
        this._invoice['series'] = 'S' + this.data.serial;
        this._invoice['invoice'] = await this.data.NextInvoice('A');
        
        this._toUpdate = true;
        this.DoRefresh('ACCOUNTINVOICE');
    }
}


