import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { CatalogObject } from './catalog';
import { Product } from './product';
import { ProductDepend } from './productdepend';
import { ProductOption } from './productoption';

export interface _ProductCategory {
    product: number;
    name: string;
    sort: number;
    type: string;
    fxmin: number;
    fxmax: number;
    status?: string;
};

interface _ProductCategoryData extends _ProductCategory {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ProductCategoryData extends CatalogObject {
    protected _category: _ProductCategoryData = {
        product: null,
        status: null,
        name: null,
        sort: null,
        type: null,
        fxmin: null,
        fxmax: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._category.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._category.created;
    }

    get status(): string {
        return this._category.status;
    }

    set status(value: string){
        if (this.patchValue(this._category, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get product(): Product{
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }
    
    get name(): string {
        return this._category.name;
    }

    set name(value: string) {
        if (this.patchValue(this._category, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get sort(): number {
        return this._category.sort;
    }

    set sort(value: number) {
        if (this.patchValue(this._category, 'sort', value)){
            this.ToUpdate = true;
        }
    }

    get type(): string {
        return this._category.type;
    }

    set type(value: string) {
        if (this.patchValue(this._category, 'type', value)){
            this.ToUpdate = true;
        }
    }

    get fxmin(): number {
        return this._category.fxmin;
    }

    set fxmin(value: number) {
        if (this.patchValue(this._category, 'fxmin', value)){
            this.ToUpdate = true;
        }
    }

    get fxmax(): number {
        return this._category.fxmax;
    }

    set fxmax(value: number) {
        if (this.patchValue(this._category, 'fxmax', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: ProductOption){
        this.AddChild('options', child, 'category');
    }

    DelOption(child: ProductOption){
        this.DelChild('options', child, 'category')
    }

    AddDepend(child: ProductDepend){
        this.AddChild('depends', child, 'category');
    }

    DelDepend(child: ProductDepend){
        this.DelChild('depends', child, 'category');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <ProductOption> {
        return this._chldlist['options'] || [];
    }

    get depends() : Array <ProductDepend> {
        return this._chldlist['depends'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            product: this._category.product,
            name: this._category.name,
            sort: this._category.sort,
            type: this._category.type,
            fxmin: this._category.fxmin,
            fxmax: this._category.fxmax
        };
    }

    protected get Depend() {
        return {
            product: { item: this.product, relation_info: { to: 'categories', by: 'product' } }     // this[by -> 'product'][to -> 'categories'] => this
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.options){
            _children.push(_item)
        }

        for(let _item of this.depends){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_category: _ProductCategory){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._category, 'product', _category['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._category, 'name', _category['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._category, 'sort', _category['sort']) || _toUpdate;
        _toUpdate = this.patchValue(this._category, 'type', _category['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._category, 'fxmin', _category['fxmin']) || _toUpdate;
        _toUpdate = this.patchValue(this._category, 'fxmax', _category['fxmax']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_category: _ProductCategory){
        if (this._patchData(_category)){
            this.ToUpdate = true;
        }
   }

    get Info(){
        return this._category;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_category: _ProductCategory){
        this._patchData(_category);

        if (_category['product']){      // update children: 'product'
            let _objid = _category['product'].toString();
            this.SetChild('product', new Product(_objid, this.data), 'product');
        }
        else {
            this.SetChild('product', null, 'product');
        }
    }

    private _ddbb(info): _ProductCategoryData {
        let _category: _ProductCategoryData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            product: info['product'] ? parseInt(info['product']) : null,
            name: info['name'],
            sort: info['sort'] ? parseInt(info['sort']) : 0,
            type: info['type'],
            fxmin: info['fxmin'] ? parseInt(info['fxmin']) : null,
            fxmax: info['fxmax'] ? parseInt(info['fxmax']) : null
        };
        return _category;
    }

    protected _OnUpdate(info){
        let _category = this._ddbb(info);
        this.patchValue(this._category, 'objid', _category['objid']);
        this.patchValue(this._category, 'created', _category['created']);
        this.DoPatchValues(_category);

        if(info['options']){     // update children: 'options'
            this.SetChildren <ProductOption> (info['options'], 'options', ProductOption, 'category');
        }

        if(info['depends']){     // update children: 'depends'
            this.SetChildren <ProductDepend> (info['depends'], 'depends', ProductDepend, 'category');
        }
    }
}

export class ProductCategory extends ProductCategoryData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('CATEGORY', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ProductCategory {
        return this._Copy(store) as ProductCategory;
    }

    /********************************/
    /* CATALOG FLAG MANAGEMENT      */
    /********************************/

    get IsCatalog(): boolean{
        return super.IsCatalog;
    }

    set IsCatalog(value: boolean){
        if (this.IsCatalog == value){
            return;
        }

        super.IsCatalog = value;

        // update the value in all the contained items
        for(let relation of [ 'options', 'depends' ]){
            if (this._children[relation]){
                for(let child of this._chldlist[relation]){
                    child.IsCatalog = value;
                }        
            } 
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status || 'AC';
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value != this.status){
            super.status = value;

            
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.product.DelCategory(this);
            }
    
            this.product.DoRefresh('PRODUCTOPT', true);
        }
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <ProductOption> {
        return super.options.sort((a, b) => {
            return a.sort - b.sort;
        });
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE');
    }

    get IsAvailable() {
        let _available = !this._isCatalog;
        if (_available){
            return this.options.some(
            option => {
                return option.IsAvailable;
            });
        }
        return _available;
    }

    get IsRequired() {
        switch(this.type){
            case 'SINGLE':
                for (let _option of this.options){
                    if (_option.IsAvailable){
                        return true;
                    }
                }
                break;

            case 'FIXED':
                if (this.fxmin > 0){
                    for (let _option of this.options){
                        if (_option.IsAvailable){
                            return true;
                        }
                    }
                }
        }
        return false;
    }

    HasCondition(option: ProductOption){
        for(let depend of this.depends){
            if (depend.option == option){
                return depend;
            }
        }
        return null;
    }

    AddCondition(option: ProductOption){
        if (!this.HasCondition(option)){
            let _depend = new ProductDepend(null, this.data);
            if (_depend){
                _depend.category = this;
                _depend.option = option;

                this.AddDepend(_depend);
            }
        }
    }

    DelCondition(option: ProductOption){
        let _depend = this.HasCondition(option);
        if (_depend){
            this.DelDepend(_depend);
        }
    }
}
