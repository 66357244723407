import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PlaceService } from './placeservice';
import { Product } from './product';
import { PrintProduct } from './printproduct';

export interface _PrintDevice {
    rasppi: number;
    status: string;
    name: string;
    type: string;
    device: string;
    width: number;
    font: number;
    top: number,
    right: number,
    bottom: number,
    left: number,
    updates: boolean,
    posudts: boolean,
    oncash: boolean,
    oncard: boolean,
    copies: number;
    bold: boolean;
    style: string;
};

interface _PrintDeviceData extends _PrintDevice {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class PrintDeviceData extends DataObject {
    protected _printer: _PrintDeviceData = {
        rasppi: null,
        status: null,
        name: null,
        type: null,
        device: null,
        width: null,
        font: null,
        top: null,
        right: null,
        bottom: null,
        left: null,
        updates: null,
        posudts: null,
        oncash: null,
        oncard: null,
        copies: null,
        bold: null,
        style: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._printer.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._printer.created;
    }

    get updated(): Date {
        return this._printer.updated || this._printer.created;
    }

    get rasppi() : PlaceService {
        return this._children['rasppi'] || null;
    }

    set rasppi(value: PlaceService){
        if (this.SetChild('rasppi', value, 'rasppi')){
            this.ToUpdate = true;
        }
    }

    get status(): string{
        return this._printer.status;
    }

    set status(value: string){
        if (this.patchValue(this._printer, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get name(): string{
        return this._printer.name;
    }

    set name(value: string){
        if (this.patchValue(this._printer, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get type(): string{
        return this._printer.type;
    }

    set type(value: string){
        if (this.patchValue(this._printer, 'type', value)){
            this.ToUpdate = true;
        }
    }

    get device(): string{
        return this._printer.device;
    }

    set device(value: string){
        if (this.patchValue(this._printer, 'device', value)){
            this.ToUpdate = true;
        }
    }

    get width(): number{
        return this._printer.width;
    }

    set width(value: number){
        if (this.patchValue(this._printer, 'width', value)){
            this.ToUpdate = true;
        }
    }

    get font(): number{
        return this._printer.font;
    }

    set font(value: number){
        if (this.patchValue(this._printer, 'font', value)){
            this.ToUpdate = true;
        }
    }

    get top(): number {
        return this._printer.top || 0;        
    }

    set top(value: number) {
        if (this.patchValue(this._printer, 'top', value)){
            this.ToUpdate = true;
        }
    }

    get right(): number {
        return this._printer.right || 0;        
    }

    set right(value: number) {
        if (this.patchValue(this._printer, 'right', value)){
            this.ToUpdate = true;
        }        
    }

    get bottom(): number {
        return this._printer.bottom || 0;        
    }

    set bottom(value: number) {
        if (this.patchValue(this._printer, 'bottom', value)){
            this.ToUpdate = true;
        }        
    }

    get left(): number {
        return this._printer.left || 0;        
    }

    set left(value: number) {
        if (this.patchValue(this._printer, 'left', value)){
            this.ToUpdate = true;
        }        
    }

    get updates(): boolean {
        return (this._printer.type == 'BAR') && this._printer.updates;
    }

    set updates(value: boolean){
        if (this.patchValue(this._printer, 'updates', value)){
            this.ToUpdate = true;
        }        
    }

    get posudts(): boolean {
        return this.updates && this._printer.posudts;

    }

    set posudts(value: boolean){
        if (this.patchValue(this._printer, 'posudts', value)){
            this.ToUpdate = true;
        }        
    } 

    get oncash(): boolean {
        return (this._printer.type == 'BAR') && this._printer.oncash;
    }

    set oncash(value: boolean){
        if (this.patchValue(this._printer, 'oncash', value)){
            this.ToUpdate = true;
        }        
    }

    get oncard(): boolean {
        return (this._printer.type == 'BAR') && this._printer.oncard;
    }

    set oncard(value: boolean){
        if (this.patchValue(this._printer, 'oncard', value)){
            this.ToUpdate = true;
        }        
    }
   
    get copies(): number {
        return this._printer.copies;
    }

    set copies(value: number){
        if (this.patchValue(this._printer, 'copies', value)){
            this.ToUpdate = true;
        }
    }

    get bold(): boolean {
        return this._printer.bold;
    }

    set bold(value: boolean){
        if (this.patchValue(this._printer, 'bold', value)){
            this.ToUpdate = true;
        }
    }

    get style(): string {
        return this._printer.style;
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddProduct(child: PrintProduct){
        this.AddChild('products', child, 'printer');
    }

    DelProduct(child: PrintProduct){
        this.DelChild('products', child, 'printer');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get products() : Array <PrintProduct> {
        return this._chldlist['products'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            rasppi: this._printer.rasppi,
            status: this._printer.status,
            name: this._printer.name,
            type: this._printer.type,
            device: this._printer.device,
            width: this._printer.width,
            font: this._printer.font,
            mt: this._printer.top,
            mr: this._printer.right,
            mb: this._printer.bottom,
            ml: this._printer.left,
            updates: this.updates ? '1' : '0',
            posudts: this.posudts ? '1' : '0',
            oncash: this.oncash ? '1' : '0',
            oncard: this.oncard ? '1' : '0',
            copies: this._printer.copies,
            bold: this._printer.bold ? '1' : '0'
        };
    }

    protected get Depend() {
        return {
            rasppi: { item: this.rasppi, relation_info: { to: 'printers', by: 'rasppi' } }   // this[by -> 'rasppi'][to -> 'printers'] => this
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.products){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_printer: _PrintDevice){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._printer, 'rasppi', _printer['rasppi']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'status', _printer['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'name', _printer['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'type', _printer['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'device', _printer['device']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'width', _printer['width']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'font', _printer['font']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'top', _printer['top']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'right', _printer['right']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'bottom', _printer['bottom']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'left', _printer['left']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'updates', _printer['updates']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'posudts', _printer['posudts']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'oncash', _printer['oncash']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'oncard', _printer['oncard']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'copies', _printer['copies']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'bold', _printer['bold']) || _toUpdate;
        _toUpdate = this.patchValue(this._printer, 'style', _printer['style']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_printer: _PrintDevice){
        if (this._patchData(_printer)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._printer;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_printer: _PrintDevice){
        this._patchData(_printer);

        if (_printer['rasppi']){     // update children: 'rasppi'
            let _objid = _printer['rasppi'].toString();
            this.SetChild('rasppi', new PlaceService(_objid, this.data, this._objoptions), 'rasppi')
        }
        else {
            this.SetChild('rasppi', null, 'rasppi');
        }
    }

    private _ddbb(info): _PrintDeviceData {
        let _printer: _PrintDeviceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            rasppi: info['rasppi'] ? parseInt(info['rasppi']) : null,
            status: info['status'],
            name: info['name'],
            type: info['type'],
            device: info['device'],
            width: info['width'] ? parseInt(info['width']) : AppConstants.defaultPrintWidth,
            font: info['font'] ? parseInt(info['font']) : AppConstants.defaultPrintFont,
            top: info['mt'] ? parseInt(info['mt']) : 0,
            right: info['mr'] ? parseInt(info['mr']) : 0,
            bottom: info['mb'] ? parseInt(info['mb']) : 0,
            left: info['ml'] ? parseInt(info['ml']) : 0,
            updates: (info['updates'] == '1'),
            posudts: (info['posudts'] == '1'),
            oncash: (info['oncash'] == '1'),
            oncard: (info['oncard'] == '1'),
            copies: info['copies'] ? parseInt(info['copies']) : 1,
            bold: (info['bold'] == '1'),
            style: info['style']
        };
        return _printer;
    }

    protected _OnUpdate(info){
        let _printer = this._ddbb(info);
        this.patchValue(this._printer, 'objid', _printer['objid']);
        this.patchValue(this._printer, 'created', _printer['created']);
        this.patchValue(this._printer, 'updated', _printer['updated']);
        this.DoPatchValues(_printer);

        if (info['products']){   // update children: 'products'
            this.SetChildren <PrintProduct> (info['products'], 'products', PrintProduct, 'printer');
        }
    }
}

export class PrintDevice extends PrintDeviceData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRINTER', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): PrintDevice {
        return this._Copy(store) as PrintDevice;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
            this.rasppi.DelPrinter(this);
        }
        else {
            this.rasppi.DoRefresh('PRINTER');
        }
    }

    get products() : Array <PrintProduct> {
        let _products = [];
        for(let _product of super.products){
            if (_product.IsValid){
                _products.push(_product);
            }
        }
        return _products;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        let _valid = (this.status && this.status != 'DE');
        if (_valid){
            _valid = !this.products.some(
            (printerproduct) => {
                return !printerproduct.product.IsValid;
            });
        }
        return _valid;
    }

    get IsDeleted(){
        return (this.status == 'DE');
    }

    SetProduct(oldproduct: Product, newproduct: Product){
        if (!this.IsValid){
            return null;
        }

        let _toUpdate = this.products.some(
        (printproduct) => {
            return (printproduct.product == oldproduct)
        });

        if (_toUpdate){
            // updating groups: only update the reference
            if (oldproduct.isgroup){
                for(let _printproduct of this.products){
                    if (_printproduct.product == oldproduct){
                        if (newproduct.IsValid){
                            _printproduct.product = newproduct;
                        }
                        else {   // remove the product
                            this.DelProduct(_printproduct);
                        }
                    }
                }

                return null;
            }

            // updating products: modify copy of offer
            else {
                let _printer: PrintDevice = this.Copy();
                if (_printer){  // replace the product on the copy
                    for(let _printproduct of _printer.products){
                        if (_printproduct.product == oldproduct){
                            if (newproduct.IsValid){
                                _printproduct.product = newproduct;
                            }
                            else {
                                _printer.DelProduct(_printproduct);
                            }
                        }
                    }
                    _printer.CopyOf = null;
                }
    
                return _printer;    
            }
        }

        return null;    // this device is not affected
    }
}
